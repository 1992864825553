var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./metabase.lib.binning.js");require("./metabase.lib.equality.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.metadata.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./shadow.js.shim.module$ttag.js");require("./metabase.util.malli.js");
'use strict';var bta,cta;
bta=function(a,b){return function f(d,e){return new $CLJS.ie(null,function(){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){for(var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m),u=0;;)if(u<m){var v=function(){var x=d+u,A=$CLJS.hd(l,u);return a.g?a.g(x,A):a.call(null,x,A)}();null!=v&&t.add(v);u+=1}else break;return $CLJS.oe($CLJS.qe(t),f(d+m,$CLJS.jc(k)))}m=function(){var x=$CLJS.z(k);return a.g?a.g(d,x):a.call(null,d,x)}();return null==m?f(d+1,$CLJS.Hc(k)):$CLJS.ae(m,f(d+1,$CLJS.Hc(k)))}return null},null,null)}(0,
b)};$CLJS.K5=function(a,b){return $CLJS.E1($CLJS.Eq,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))};$CLJS.L5=function(a,b){return $CLJS.E1($CLJS.Cq,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null))};$CLJS.M5=function(a,b){return $CLJS.j5.g(a,$CLJS.Cd(b,$CLJS.lD)?$CLJS.lD.h(b):b)};cta=new $CLJS.M(null,"same-temporal-bucket?","same-temporal-bucket?",2110106995);$CLJS.N_.m(null,$CLJS.aF,function(a,b){var c=$CLJS.Be($CLJS.aF.h($CLJS.EV(a,b)));return $CLJS.n(c)?$CLJS.fD("Grouped by {0}",$CLJS.H([$CLJS.is([" ",$CLJS.p.h($CLJS.LD("and"))," "].join(""),function(){return function f(e){return new $CLJS.ie(null,function(){for(;;){var k=$CLJS.y(e);if(k){if($CLJS.vd(k)){var l=$CLJS.ic(k),m=$CLJS.D(l),t=$CLJS.le(m);a:for(var u=0;;)if(u<m){var v=$CLJS.hd(l,u);v=$CLJS.j_.v(a,b,v,$CLJS.k_);t.add(v);u+=1}else{l=!0;break a}return l?$CLJS.oe($CLJS.qe(t),f($CLJS.jc(k))):$CLJS.oe($CLJS.qe(t),
null)}t=$CLJS.z(k);return $CLJS.ae($CLJS.j_.v(a,b,t,$CLJS.k_),f($CLJS.Hc(k)))}return null}},null,null)}(c)}())])):null});$CLJS.N5=function(){function a(d,e,f){f=$CLJS.pV($CLJS.id(f)?f.g?f.g(d,e):f.call(null,d,e):f);return $CLJS.J1(d,e,$CLJS.aF,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.O5=function(){function a(d,e){return $CLJS.Be($CLJS.aF.h($CLJS.EV(d,e)))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.P5=function(){function a(d,e){var f=$CLJS.O5.g(d,e);return null==f?null:$CLJS.gk.g(function(k){return $CLJS.R.j($CLJS.h_.j(d,e,k),$CLJS.OJ,$CLJS.dK)},f)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.Q5=function(){function a(d,e){var f=function(){var l=$CLJS.EV(d,e);return $CLJS.W_.v(d,e,l,new $CLJS.h(null,1,[$CLJS.H_,!1],null))}();if($CLJS.y(f)){var k=$CLJS.Wf.g($CLJS.N,bta(function(l,m){var t=$CLJS.S1.M(d,e,m,f,new $CLJS.h(null,1,[$CLJS.P1,!0],null));return $CLJS.n(t)?new $CLJS.P(null,2,5,$CLJS.Q,[t,new $CLJS.P(null,2,5,$CLJS.Q,[l,m],null)],null):null},function(){var l=$CLJS.O5.g(d,e);return $CLJS.n(l)?l:$CLJS.xf}()));return $CLJS.gk.g(function(l){var m=k.h?k.h(l):k.call(null,l),t=$CLJS.I(m,
0,null),u=$CLJS.I(m,1,null);m=$CLJS.e5(u);u=$CLJS.l2(u);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.Bk);l=$CLJS.R.j(l,$CLJS.G5,!0);l=$CLJS.n(m)?$CLJS.M5(l,m):l;l=$CLJS.n(u)?$CLJS.e3(l,u):l;return $CLJS.n(t)?$CLJS.R.j(l,$CLJS.t_,t):l},f)}return null}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.R5=function(){function a(d,e,f,k){k=$CLJS.O(k);var l=$CLJS.J.g(k,cta);return $CLJS.Be($CLJS.Wf.j($CLJS.xf,$CLJS.ef(function(m){var t=$CLJS.S1.M(d,e,m,new $CLJS.P(null,1,5,$CLJS.Q,[f],null),new $CLJS.h(null,1,[$CLJS.P1,!0],null));return $CLJS.n(t)?$CLJS.n(l)?$CLJS.E.g($CLJS.l2(m),$CLJS.l2(f)):!0:t}),$CLJS.O5.g(d,e)))}function b(d,e,f){return c.v?c.v(d,e,f,null):c.call(null,d,e,f,null)}var c=null;c=function(d,e,f,k){switch(arguments.length){case 3:return b.call(this,d,e,f);case 4:return a.call(this,
d,e,f,k)}throw Error("Invalid arity: "+arguments.length);};c.j=b;c.v=a;return c}();$CLJS.S5=function(){function a(d,e,f){return $CLJS.cb(function(k,l){return $CLJS.C5.j(k,e,l)},d,$CLJS.R5.j(d,e,f))}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();