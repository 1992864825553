var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./malli.core.js");require("./malli.error.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.temporal.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.schema.literal.js");require("./metabase.mbql.schema.helpers.js");require("./shadow.js.shim.module$ttag.js");require("./clojure.string.js");require("./metabase.util.malli.registry.js");
'use strict';var RK,SK,TK,nja,oja,XK,pja,$K,aL,bL,cL,qja,dL,eL,gL,hL,UK,iL,rja,jL,sja,kL,lL,mL,tja,nL,oL,uja,pL,vja,qL,rL,wja,tL,xja,yja,zja,vL,wL,xL,yL,zL,AL,BL,CL,Aja,DL,EL,FL,HL,IL,Bja,JL,KL,LL,ML,Cja,NL,OL,PL,QL,Dja,RL,SL,TL,UL,Eja,VL,Fja,WL,XL,YL,Gja,ZL,$L,aM,bM,dM,eM,Hja,fM,gM,Ija,hM,iM,jM,kM,lM,Jja,mM,Kja,nM,oM,qM,Lja,rM,sM,tM,Mja,wM,xM,Nja,Oja,yM,AM,BM,CM,Pja,FM,GM,Qja,Rja,Sja,HM,IM,JM,KM,LM,MM,Tja,NM,Uja,OM,PM,RM,TM,UM,VM,WM,Vja,Wja,YM,Xja,Yja,ZM,$M,aN,bN,cN,Zja,dN,$ja,aka,eN,fN,gN,hN,iN,
bka,cka,dka,jN,eka,kN,fka,mN,nN,gka,pN,qN,rN,sN,hka,vN,ika,jka,wN,xN,yN,kka,zN,AN,BN,CN,lka,EN,FN,GN,HN,IN,mka,JN,KN,nka,LN,MN,NN,ON,PN,QN,SN,oka,TN,pka,UN,VN,WN,qka,rka,ska,YN,ZN,$N,tka,aO,bO,cO,dO,uka,vka,fO,wka,xka,gO,hO,iO,jO,kO,yka,lO,mO,nO,oO,zka,pO,qO,rO,sO,uO,Aka,vO,wO,Bka,xO,yO,zO,Cka,AO,Dka,CO,Eka,DO,EO,FO,GO,HO,IO,JO,Fka,Gka,Hka,KO,Ika,LO,MO,Jka,Kka,NO,OO,PO,Lka,QO,Mka,RO,Nka,Oka,SO,Pka,Qka,Rka,VO,WO,Ska,XO,YO,$O,Tka,aP,Uka,bP,cP,dP,Vka,eP,fP,gP,hP,Wka,jP,kP,Xka,lP,mP,nP,oP,pP,qP,rP,sP,
Yka,tP,uP,Zka,vP,$ka,wP,xP,yP,ala,zP,AP,bla,cla,BP,CP,DP,dla,ela,EP,fla,gla,hla,GP,ila,IP,jla,kla,JP,KP,lla,mla,nla,LP,MP,NP,OP,PP,QP,RP,ola,SP,TP,pla,UP,qla,rla,sla,VP,WP,XP,tla,YP,ZP,ula,$P,vla,aQ,wla,xla,cQ,dQ,eQ,fQ,gQ,yla,hQ,iQ,zla,jQ,kQ,lQ,mQ,nQ,oQ,pQ,qQ,Ala,rQ,sQ,uQ,vQ,wQ,xQ,yQ,zQ,AQ,BQ,CQ,DQ,Bla,EQ,FQ,GQ,HQ,IQ,Cla,JQ,KQ,LQ,MQ,Dla,OQ,Ela,Fla,QQ,Gla,RQ,Hla,SQ,Ila,Jla,TQ,Kla,UQ,WQ,XQ,Lla,Mla,aR,bR,Nla,Ola,cR,dR,eR,fR,gR,Pla,hR,iR,kR,lR,Qla;
$CLJS.QK=function(a){var b=$CLJS.Bn.g(a,null),c=$CLJS.oE(b,$CLJS.Cr,function(d){return $CLJS.Hm(d,$CLJS.xf)});return function(){function d(k,l,m){return(l=$CLJS.y(c.j?c.j(k,l,m):c.call(null,k,l,m)))?new $CLJS.h(null,3,[$CLJS.Pk,b,$CLJS.ej,k,$CLJS.Ir,l],null):null}function e(k){return f.j(k,$CLJS.xf,$CLJS.xf)}var f=null;f=function(k,l,m){switch(arguments.length){case 1:return e.call(this,k);case 3:return d.call(this,k,l,m)}throw Error("Invalid arity: "+arguments.length);};f.h=e;f.j=d;return f}()};
RK=function(a,b){return $CLJS.sd(a)?$CLJS.J.g(a,b):a};SK=function(a,b,c,d){d=$CLJS.n(d)?d:$CLJS.Km($CLJS.Bn.g($CLJS.Pk.h(a),null));if($CLJS.n(b)){var e=RK($CLJS.Fs.h(b),c);$CLJS.n(e)?(e=$CLJS.zp(e,d),a=e.g?e.g(a,d):e.call(null,a,d)):a=null;return $CLJS.n(a)?a:RK($CLJS.us.h(b),c)}return null};TK=function(a,b){return $CLJS.qd(a)||$CLJS.gl(a)?$CLJS.J.g(a,b):$CLJS.rd(a)?$CLJS.J.g($CLJS.yf(a),b):null};
nja=function(a,b){a=$CLJS.O(a);var c=$CLJS.J.g(a,$CLJS.Pk),d=$CLJS.J.g(a,$CLJS.Ri);b=$CLJS.O(b);var e=$CLJS.J.j(b,$CLJS.Ir,$CLJS.lda),f=$CLJS.J.j(b,$CLJS.gda,!0),k=$CLJS.J.g(b,$CLJS.Gs),l=$CLJS.J.j(b,$CLJS.Ds,$CLJS.Bs),m=SK(a,$CLJS.Bp.h(c),k,b);if($CLJS.n(m))return m;m=SK(a,$CLJS.Ur.h(c),k,b);if($CLJS.n(m))return m;m=SK(a,e.h?e.h(d):e.call(null,d),k,b);if($CLJS.n(m))return m;m=SK(a,function(){var t=$CLJS.nn.h(c);return e.h?e.h(t):e.call(null,t)}(),k,b);if($CLJS.n(m))return m;m=SK(a,$CLJS.Bp.h(c),
l,b);if($CLJS.n(m))return m;m=SK(a,$CLJS.Ur.h(c),l,b);if($CLJS.n(m))return m;d=SK(a,e.h?e.h(d):e.call(null,d),l,b);if($CLJS.n(d))return d;d=SK(a,function(){var t=$CLJS.nn.h(c);return e.h?e.h(t):e.call(null,t)}(),l,b);if($CLJS.n(d))return d;k=$CLJS.n(f)?SK(a,e.h?e.h($CLJS.zs):e.call(null,$CLJS.zs),k,b):f;return $CLJS.n(k)?k:$CLJS.n(f)?SK(a,e.h?e.h($CLJS.zs):e.call(null,$CLJS.zs),l,b):f};
oja=function(a,b,c){a=$CLJS.Q;var d=$CLJS.Wf.g;var e=$CLJS.Mk.h(b);var f=$CLJS.O(b);var k=$CLJS.J.g(f,$CLJS.Pk),l=$CLJS.O(c);f=$CLJS.J.g(l,$CLJS.Gs);l=$CLJS.J.j(l,$CLJS.Ds,$CLJS.Bs);k=$CLJS.Bp.h(k);f=RK($CLJS.Cs.h(k),f);f=$CLJS.n(f)?f:RK($CLJS.Cs.h(k),l);d=d.call($CLJS.Wf,e,f);return new $CLJS.P(null,2,5,a,[d,nja(b,c)],null)};
$CLJS.VK=function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.ej);a=$CLJS.J.g(b,$CLJS.Ir);var d=$CLJS.O(null),e=$CLJS.J.j(d,$CLJS.hda,$CLJS.zi),f=$CLJS.J.j(d,$CLJS.jda,oja);return $CLJS.n(a)?$CLJS.cb(function(k,l){var m=f.j?f.j(b,l,d):f.call(null,b,l,d),t=$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);l=$CLJS.R.j(l,$CLJS.zi,m);l=e.h?e.h(l):e.call(null,l);return UK(k,c,t,l)},null,a):null};
XK=function(a,b,c){var d=$CLJS.Bz($CLJS.q($CLJS.ZC),new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null));if($CLJS.n(d))return d;c=c.o?c.o():c.call(null);$CLJS.zh.v($CLJS.ZC,$CLJS.WK,new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null),c);return c};$CLJS.YK=function(a){return XK($CLJS.Lj,a,function(){return $CLJS.pE.h(a)})};pja=function(){var a=ZK;return XK($CLJS.Cr,a,function(){var b=$CLJS.pE.h(a),c=$CLJS.QK(a);return function(d){return $CLJS.n(b.h?b.h(d):b.call(null,d))?null:c.h?c.h(d):c.call(null,d)}})};
$K=function(a){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,$CLJS.he(a)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,a],null):a],null)};aL=function(a){if($CLJS.ud(a)){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);switch(b instanceof $CLJS.M?b.S:null){case "optional":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Lq,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$K(c)],null)],null);case "rest":return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kq,$K(c)],null);default:return $K(a)}}else return $K(a)};
bL=function(a,b){var c=$CLJS.ud(b);return c?(c=$CLJS.z(b)instanceof $CLJS.M)?$CLJS.pd(a)?(b=$CLJS.z(b),a=$CLJS.Wg(a),a.h?a.h(b):a.call(null,b)):$CLJS.E.g(a,$CLJS.z(b)):c:c};
cL=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,["not a ",$CLJS.p.h(a)," clause"].join("")],null),$CLJS.Qe(bL,a)],null),$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wq,new $CLJS.P(null,2,5,$CLJS.Q,["tag",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,a],null)],null)],null),function(){return function e(d){return new $CLJS.ie(null,function(){for(;;){var f=$CLJS.y(d);if(f){if($CLJS.vd(f)){var k=$CLJS.ic(f),l=$CLJS.D(k),m=$CLJS.le(l);
a:for(var t=0;;)if(t<l){var u=$CLJS.hd(k,t),v=$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);v=new $CLJS.P(null,2,5,$CLJS.Q,[v,aL(u)],null);m.add(v);t+=1}else{k=!0;break a}return k?$CLJS.oe($CLJS.qe(m),e($CLJS.jc(f))):$CLJS.oe($CLJS.qe(m),null)}k=$CLJS.z(f);m=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[m,aL(k)],null),e($CLJS.Hc(f)))}return null}},null,null)}($CLJS.Xr(2,2,b))}())],null)};qja=function(a){return $CLJS.ud(a)&&$CLJS.z(a)instanceof $CLJS.M?$CLJS.z(a):null};
dL=function(a){return $CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.xi,qja,$CLJS.us,["valid instance of one of these MBQL clauses: ",$CLJS.is(", ",$CLJS.cf.g($CLJS.z,a))].join("")],null)],null),function(){return function d(c){return new $CLJS.ie(null,function(){for(;;){var e=$CLJS.y(c);if(e){if($CLJS.vd(e)){var f=$CLJS.ic(e),k=$CLJS.D(f),l=$CLJS.le(k);a:for(var m=0;;)if(m<k){var t=$CLJS.hd(f,m),u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);u=new $CLJS.P(null,2,5,$CLJS.Q,[u,
$CLJS.he(t)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,t],null):t],null);l.add(u);m+=1}else{f=!0;break a}return f?$CLJS.oe($CLJS.qe(l),d($CLJS.jc(e))):$CLJS.oe($CLJS.qe(l),null)}f=$CLJS.z(e);l=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return $CLJS.ae(new $CLJS.P(null,2,5,$CLJS.Q,[l,$CLJS.he(f)?new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,f],null):f],null),d($CLJS.Hc(e)))}return null}},null,null)}(a)}())};
eL=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PK],null)],null)};$CLJS.fL={};gL={};hL={};$CLJS.WK=function WK(a,b,c){var e=$CLJS.y(b);b=$CLJS.z(e);var f=$CLJS.B(e);if(f){e=$CLJS.R.j;var k=$CLJS.J.g(a,b);c=WK.j?WK.j(k,f,c):WK.call(null,k,f,c);a=e.call($CLJS.R,a,b,c)}else a=$CLJS.R.j(a,b,c);return a};
UK=function UK(a,b,c,d){var f=$CLJS.y(c);c=$CLJS.z(f);var k=$CLJS.B(f),l=TK(b,c);f=$CLJS.n(a)?a:$CLJS.rd(b)?$CLJS.xf:$CLJS.td(b)?$CLJS.N:$CLJS.fd(b);return $CLJS.n($CLJS.n(c)?$CLJS.As.h($CLJS.ld(f)):c)?a:$CLJS.n(c)?(b=TK(f,c),d=UK.v?UK.v(b,l,k,d):UK.call(null,b,l,k,d),$CLJS.ll(c)&&$CLJS.rd(f)&&c>$CLJS.D(f)&&(b=$CLJS.Ze(c-$CLJS.D(f),null),b=$CLJS.bf.g(f,b),f=null==f||$CLJS.yd(f)?b:$CLJS.Wf.g($CLJS.fd(f),b)),null==f||$CLJS.gl(f)?$CLJS.R.j(f,c,d):$CLJS.qd(f)?$CLJS.Yd.g(f,d):$CLJS.Ne.g($CLJS.V,$CLJS.R.j($CLJS.yf(f),
c,d))):$CLJS.sd(a)?(c=new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ida],null),UK.v?UK.v(f,b,c,d):UK.call(null,f,b,c,d)):$CLJS.n($CLJS.As.h($CLJS.ld(f)))?$CLJS.Yd.g(f,d):$CLJS.ud($CLJS.Be(f))?f:$CLJS.kd(new $CLJS.P(null,1,5,$CLJS.Q,[d],null),new $CLJS.h(null,1,[$CLJS.As,!0],null))};iL=new $CLJS.M("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",-662824303);rja=new $CLJS.r("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",-1242286173,null);
jL=new $CLJS.M("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",-345484364);sja=new $CLJS.r("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",1275783375,null);kL=new $CLJS.M("metabase.mbql.schema","datetime-subtract","metabase.mbql.schema/datetime-subtract",1412149596);lL=new $CLJS.M("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",-985876836);mL=new $CLJS.M("metabase.mbql.schema","substring","metabase.mbql.schema/substring",188871083);
tja=new $CLJS.r("metabase.mbql.schema","replace","metabase.mbql.schema/replace",1269362826,null);nL=new $CLJS.M("metabase.mbql.schema","TimeLiteral","metabase.mbql.schema/TimeLiteral",-822346498);oL=new $CLJS.M("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",549586740);uja=new $CLJS.M(null,"disable-mbql-\x3enative?","disable-mbql-\x3enative?",1103196677);pL=new $CLJS.M("metabase.mbql.schema","StringExpressionArg","metabase.mbql.schema/StringExpressionArg",596241960);
vja=new $CLJS.M(null,"from","from",1815293044);qL=new $CLJS.M("metabase.mbql.schema","and","metabase.mbql.schema/and",-86675456);rL=new $CLJS.r(null,"does-not-contain","does-not-contain",1365584674,null);wja=new $CLJS.M(null,"add-default-userland-constraints?","add-default-userland-constraints?",2039156190);$CLJS.sL=new $CLJS.M(null,"snippet-name","snippet-name",819240328);tL=new $CLJS.M("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",560735368);
xja=new $CLJS.r("metabase.mbql.schema","max","metabase.mbql.schema/max",-1397867614,null);yja=new $CLJS.r("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",-1600012951,null);$CLJS.uL=new $CLJS.M(null,"aggregation-options","aggregation-options",-1904917550);zja=new $CLJS.r("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",596526831,null);vL=new $CLJS.M("location","zip_code","location/zip_code",1641155222);
wL=new $CLJS.r(null,"ExpressionArg","ExpressionArg",1060743736,null);xL=new $CLJS.r(null,"get-hour","get-hour",-2031721710,null);yL=new $CLJS.M("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",-140332301);zL=new $CLJS.r(null,"ag:var","ag:var",-1804440286,null);AL=new $CLJS.M("metabase.mbql.schema","IntGreaterThanZeroOrNumericExpression","metabase.mbql.schema/IntGreaterThanZeroOrNumericExpression",1485789132);
BL=new $CLJS.M("metabase.mbql.schema","exp","metabase.mbql.schema/exp",-1188751039);CL=new $CLJS.M("metabase.mbql.schema","time-interval","metabase.mbql.schema/time-interval",-364748152);Aja=new $CLJS.M(null,"lon-max","lon-max",1590224717);DL=new $CLJS.M("metabase.mbql.schema","asc","metabase.mbql.schema/asc",-302489464);EL=new $CLJS.r(null,"stddev","stddev",775056588,null);FL=new $CLJS.M("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-1954451840);
$CLJS.GL=new $CLJS.M(null,"snippet","snippet",953581994);HL=new $CLJS.r("metabase.mbql.schema","case","metabase.mbql.schema/case",2100482976,null);IL=new $CLJS.M(null,"string-expression","string-expression",-1395337766);Bja=new $CLJS.M(null,"lat-field","lat-field",-830652957);JL=new $CLJS.M("metabase.mbql.schema","now","metabase.mbql.schema/now",-975392912);KL=new $CLJS.M("metabase.mbql.schema","avg","metabase.mbql.schema/avg",1123586863);
LL=new $CLJS.M("metabase.mbql.schema","not","metabase.mbql.schema/not",-1753911131);ML=new $CLJS.M("metabase.mbql.schema","StringExpression","metabase.mbql.schema/StringExpression",-670335423);Cja=new $CLJS.r("metabase.mbql.schema","min","metabase.mbql.schema/min",-1534510688,null);NL=new $CLJS.M("location","country","location/country",1666636202);OL=new $CLJS.M("metabase.mbql.schema","trim","metabase.mbql.schema/trim",350521664);
PL=new $CLJS.M("metabase.mbql.schema","Reference","metabase.mbql.schema/Reference",-770092616);QL=new $CLJS.M("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",-1080110117);Dja=new $CLJS.r("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",-1224231786,null);RL=new $CLJS.M(null,"unary","unary",-989314568);SL=new $CLJS.M("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",297284410);
TL=new $CLJS.M("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",324961083);UL=new $CLJS.r(null,"ceil","ceil",-184398425,null);Eja=new $CLJS.M(null,"lon-min","lon-min",-787291357);VL=new $CLJS.r(null,"NonBlankString","NonBlankString",-719244809,null);Fja=new $CLJS.M(null,"match","match",1220059550);WL=new $CLJS.r(null,"count-where","count-where",2025939247,null);XL=new $CLJS.M("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",1844658706);
YL=new $CLJS.M(null,"max-results-bare-rows","max-results-bare-rows",2096475889);Gja=new $CLJS.M(null,"embedded-question","embedded-question",-2146473954);ZL=new $CLJS.M("metabase.mbql.schema","WidgetType","metabase.mbql.schema/WidgetType",1831406784);$L=new $CLJS.r(null,"sum","sum",1777518341,null);aM=new $CLJS.M("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",197727284);bM=new $CLJS.r("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",-807630752,null);
$CLJS.cM=new $CLJS.M("date","range","date/range",1647265776);dM=new $CLJS.r(null,"between","between",-1523336493,null);eM=new $CLJS.M(null,"clause-form","clause-form",1820463737);Hja=new $CLJS.r("metabase.mbql.schema","log","metabase.mbql.schema/log",-899459908,null);fM=new $CLJS.M("metabase.mbql.schema","Query","metabase.mbql.schema/Query",1321874752);gM=new $CLJS.r(null,"field","field",338095027,null);Ija=new $CLJS.M(null,"segment-id","segment-id",1810133590);
hM=new $CLJS.M("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",94927598);iM=new $CLJS.r(null,"not-null","not-null",313812992,null);jM=new $CLJS.M("metabase.mbql.schema","Joins","metabase.mbql.schema/Joins",1376278278);kM=new $CLJS.M("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",-1587636578);lM=new $CLJS.M("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",556298105);Jja=new $CLJS.M(null,"template-tag","template-tag",310841038);
mM=new $CLJS.M(null,"invalid","invalid",412869516);Kja=new $CLJS.r("metabase.mbql.schema","datetime-diff","metabase.mbql.schema/datetime-diff",1838258811,null);nM=new $CLJS.M("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",1473793750);oM=new $CLJS.r(null,"regex-match-first","regex-match-first",983231809,null);$CLJS.pM=new $CLJS.M(null,"context","context",-830191113);qM=new $CLJS.r(null,"get-year","get-year",704520253,null);
Lja=new $CLJS.M(null,"format-rows?","format-rows?",992129486);rM=new $CLJS.M("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",336705322);sM=new $CLJS.M("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",829238608);tM=new $CLJS.M("metabase.mbql.schema","variable","metabase.mbql.schema/variable",672451083);$CLJS.uM=new $CLJS.M(null,"parameters","parameters",-1229919748);Mja=new $CLJS.M(null,"json-download","json-download",-971130133);
$CLJS.vM=new $CLJS.M(null,"card","card",-1430355152);wM=new $CLJS.M(null,"string-or-field","string-or-field",-1970678542);xM=new $CLJS.r(null,"!\x3d","!\x3d",-201205829,null);Nja=new $CLJS.r("metabase.mbql.schema","ag:var","metabase.mbql.schema/ag:var",1295047163,null);Oja=new $CLJS.r("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",-1404628626,null);yM=new $CLJS.r(null,"expression","expression",1842843403,null);$CLJS.zM=new $CLJS.M("date","month-year","date/month-year",1948031290);
AM=new $CLJS.M("metabase.mbql.schema","BooleanExpression","metabase.mbql.schema/BooleanExpression",-2109835363);BM=new $CLJS.r(null,"ends-with","ends-with",1183740516,null);CM=new $CLJS.M("metabase.mbql.schema","time","metabase.mbql.schema/time",727162193);$CLJS.DM=new $CLJS.M(null,"joins","joins",1033962699);Pja=new $CLJS.M(null,"slug","slug",2029314850);$CLJS.EM=new $CLJS.M(null,"source-field","source-field",933829534);FM=new $CLJS.r(null,"Field","Field",430385967,null);
GM=new $CLJS.r(null,"convert-timezone","convert-timezone",1515728526,null);Qja=new $CLJS.r("metabase.mbql.schema","absolute-datetime","metabase.mbql.schema/absolute-datetime",-1180642019,null);Rja=new $CLJS.M(null,"disable-max-results?","disable-max-results?",857693204);Sja=new $CLJS.M(null,"items","items",1031954938);HM=new $CLJS.M(null,"datetime-expression","datetime-expression",391782195);IM=new $CLJS.M("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",1605865100);
JM=new $CLJS.M(null,"more","more",-2058821800);KM=new $CLJS.M(null,"first-clause","first-clause",-20953491);LM=new $CLJS.M("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",-671453147);MM=new $CLJS.r(null,"OrderComparable","OrderComparable",772072595,null);Tja=new $CLJS.r("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",-2117292579,null);NM=new $CLJS.r(null,"contains","contains",-1977535957,null);
Uja=new $CLJS.r("metabase.mbql.schema","floor","metabase.mbql.schema/floor",422926927,null);OM=new $CLJS.r("metabase.mbql.schema","expression","metabase.mbql.schema/expression",370334320,null);PM=new $CLJS.M("number","\x3d","number/\x3d",-2094581309);$CLJS.QM=new $CLJS.M(null,"widget-type","widget-type",1836256899);RM=new $CLJS.M("metabase.mbql.schema","max","metabase.mbql.schema/max",1256568155);$CLJS.SM=new $CLJS.M(null,"fingerprint","fingerprint",598613022);
TM=new $CLJS.r(null,"is-null","is-null",-356519403,null);UM=new $CLJS.M(null,"other-clauses","other-clauses",1570511021);VM=new $CLJS.M("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",-1003477115);WM=new $CLJS.M("metabase.mbql.schema","upper","metabase.mbql.schema/upper",-646839893);Vja=new $CLJS.M(null,"map-tiles","map-tiles",1961865797);$CLJS.XM=new $CLJS.M(null,"required","required",1807647006);Wja=new $CLJS.M(null,"embedded-csv-download","embedded-csv-download",1858870014);
YM=new $CLJS.M(null,"unnamed-aggregation","unnamed-aggregation",-93854280);Xja=new $CLJS.M(null,"datetime-x","datetime-x",1519265947);Yja=new $CLJS.M(null,"datetime-y","datetime-y",-1666955771);ZM=new $CLJS.r(null,"CaseClauses","CaseClauses",-1749071354,null);$M=new $CLJS.M(null,"date-arithmetics","date-arithmetics",-1832808309);aN=new $CLJS.M("string","contains","string/contains",1602423827);bN=new $CLJS.M("metabase.mbql.schema","count-where","metabase.mbql.schema/count-where",1430203983);
cN=new $CLJS.M("metabase.mbql.schema","or","metabase.mbql.schema/or",1154080514);Zja=new $CLJS.r("metabase.mbql.schema","metric","metabase.mbql.schema/metric",1030004285,null);dN=new $CLJS.M("string","!\x3d","string/!\x3d",-1083772573);$ja=new $CLJS.r(null,"IntGreaterThanZeroOrNumericExpression","IntGreaterThanZeroOrNumericExpression",2098230228,null);aka=new $CLJS.r("metabase.mbql.schema","count","metabase.mbql.schema/count",678066683,null);
eN=new $CLJS.M("metabase.mbql.schema","min","metabase.mbql.schema/min",1119925081);fN=new $CLJS.r("metabase.mbql.schema","field","metabase.mbql.schema/field",1566852856,null);gN=new $CLJS.M("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",-11600894);hN=new $CLJS.M("metabase.mbql.schema","Join","metabase.mbql.schema/Join",-2100258965);iN=new $CLJS.r(null,"share","share",1051097594,null);
bka=new $CLJS.r("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",-823840515,null);cka=new $CLJS.r("metabase.mbql.schema","round","metabase.mbql.schema/round",314817788,null);dka=new $CLJS.r("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",2007639089,null);jN=new $CLJS.M("metabase.mbql.schema","distinct","metabase.mbql.schema/distinct",1830595254);eka=new $CLJS.M(null,"xlsx-download","xlsx-download",-1622892009);
kN=new $CLJS.M("metabase.mbql.schema","contains","metabase.mbql.schema/contains",-241280695);fka=new $CLJS.r("metabase.mbql.schema","contains","metabase.mbql.schema/contains",1399250832,null);$CLJS.lN=new $CLJS.M(null,"collection","collection",-683361892);mN=new $CLJS.M("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",45949435);nN=new $CLJS.M("metabase.mbql.schema","median","metabase.mbql.schema/median",414562044);
$CLJS.oN=new $CLJS.M("metadata","dataset-metadata","metadata/dataset-metadata",-728376469);gka=new $CLJS.r("metabase.mbql.schema","is-empty","metabase.mbql.schema/is-empty",1686480962,null);pN=new $CLJS.M("number","\x3c\x3d","number/\x3c\x3d",-1499316353);qN=new $CLJS.r(null,"metric","metric",2049329604,null);rN=new $CLJS.r(null,"concat","concat",-467652465,null);sN=new $CLJS.M(null,"variable","variable",-281346492);$CLJS.tN=new $CLJS.M("date","quarter-year","date/quarter-year",-1453950150);
hka=new $CLJS.r(null,"TimeUnit","TimeUnit",1200517789,null);$CLJS.uN=new $CLJS.M("date","relative","date/relative",25987732);vN=new $CLJS.M("location","city","location/city",-1746973325);ika=new $CLJS.M(null,"public-dashboard","public-dashboard",645968405);jka=new $CLJS.r("metabase.mbql.schema","desc","metabase.mbql.schema/desc",-2050113424,null);wN=new $CLJS.M("metabase.mbql.schema","get-day","metabase.mbql.schema/get-day",1054422818);xN=new $CLJS.M("number","\x3e\x3d","number/\x3e\x3d",-1670691032);
yN=new $CLJS.M("number","between","number/between",97700581);kka=new $CLJS.r("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",-2078342061,null);zN=new $CLJS.M("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",-576867936);AN=new $CLJS.r(null,"sqrt","sqrt",370479598,null);BN=new $CLJS.M("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",515738880);CN=new $CLJS.M("metabase.mbql.schema","expression","metabase.mbql.schema/expression",-1270197207);
$CLJS.DN=new $CLJS.M(null,"semantic_type","semantic_type",272485089);lka=new $CLJS.M(null,"metric-id","metric-id",-686486942);EN=new $CLJS.r(null,"*","*",345799209,null);FN=new $CLJS.r(null,"+","+",-740910886,null);GN=new $CLJS.r(null,"-","-",-471816912,null);HN=new $CLJS.r(null,"template-tag","template-tag",1951372565,null);IN=new $CLJS.M(null,"allowed-for","allowed-for",122724334);mka=new $CLJS.M(null,"question","question",-1411720117);JN=new $CLJS.r(null,"asc","asc",1997386096,null);
KN=new $CLJS.M("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",-391950078);nka=new $CLJS.M(null,"pulse-id","pulse-id",1331432237);LN=new $CLJS.M("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",277590849);MN=new $CLJS.r(null,"\x3c\x3d","\x3c\x3d",1244895369,null);NN=new $CLJS.r(null,"\x3c","\x3c",993667236,null);ON=new $CLJS.r(null,"\x3e","\x3e",1085014381,null);PN=new $CLJS.r(null,"\x3d","\x3d",-1501502141,null);
QN=new $CLJS.M("metabase.mbql.schema","OrderComparable","metabase.mbql.schema/OrderComparable",1970325905);$CLJS.RN=new $CLJS.M(null,"source-metadata","source-metadata",-477816085);SN=new $CLJS.M("string","ends-with","string/ends-with",302681156);oka=new $CLJS.r("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-265030428,null);TN=new $CLJS.r(null,"RelativeDatetimeUnit","RelativeDatetimeUnit",-1157637501,null);pka=new $CLJS.r(null,"MetricID","MetricID",-2128635641,null);
UN=new $CLJS.r(null,"and","and",668631710,null);VN=new $CLJS.r(null,"\x3e\x3d","\x3e\x3d",1016916022,null);WN=new $CLJS.r(null,"round","round",-645002441,null);qka=new $CLJS.M(null,"to","to",192099007);$CLJS.XN=new $CLJS.M("date","single","date/single",1554682003);rka=new $CLJS.M(null,"action-id","action-id",-1727958578);ska=new $CLJS.r("metabase.mbql.schema","not-null","metabase.mbql.schema/not-null",1248581449,null);YN=new $CLJS.r(null,"exp","exp",1378825265,null);
ZN=new $CLJS.r(null,"Filter","Filter",-424893332,null);$N=new $CLJS.r(null,"cum-count","cum-count",-323900016,null);tka=new $CLJS.M(null,"dashboard-id","dashboard-id",1965414288);aO=new $CLJS.M("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-1731861963);bO=new $CLJS.M("metabase.mbql.schema","abs","metabase.mbql.schema/abs",152348622);cO=new $CLJS.M("metabase.mbql.schema","desc","metabase.mbql.schema/desc",604322345);
dO=new $CLJS.M(null,"numeric-expression","numeric-expression",-1661337235);$CLJS.eO=new $CLJS.M(null,"source-table","source-table",-225307692);uka=new $CLJS.M(null,"embedded-dashboard","embedded-dashboard",-485078014);vka=new $CLJS.r("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",-824662873,null);fO=new $CLJS.r(null,"floor","floor",-772394748,null);wka=new $CLJS.M(null,"middleware","middleware",1462115504);
xka=new $CLJS.r("metabase.mbql.schema","share","metabase.mbql.schema/share",1977664161,null);gO=new $CLJS.M(null,"requires-features","requires-features",-101116256);hO=new $CLJS.M("metabase.mbql.schema","power","metabase.mbql.schema/power",2136530448);iO=new $CLJS.M(null,"clause-name","clause-name",-996419059);jO=new $CLJS.M("metabase.mbql.schema","get-year","metabase.mbql.schema/get-year",-1905561955);kO=new $CLJS.r(null,"now","now",-9994004,null);
yka=new $CLJS.r("metabase.mbql.schema","datetime-add","metabase.mbql.schema/datetime-add",654654691,null);lO=new $CLJS.r(null,"not","not",1044554643,null);mO=new $CLJS.r(null,"avg","avg",1837937727,null);nO=new $CLJS.r(null,"sum-where","sum-where",-519087341,null);oO=new $CLJS.M(null,"max-results","max-results",-32858165);zka=new $CLJS.r("metabase.mbql.schema","get-second","metabase.mbql.schema/get-second",560421410,null);pO=new $CLJS.r(null,"case","case",-1510733573,null);
qO=new $CLJS.r(null,"distinct","distinct",-148347594,null);rO=new $CLJS.r(null,"get-second","get-second",-425414791,null);sO=new $CLJS.M("metabase.mbql.schema","ends-with","metabase.mbql.schema/ends-with",537143190);$CLJS.tO=new $CLJS.M(null,"join-alias","join-alias",1454206794);uO=new $CLJS.r(null,"is-empty","is-empty",600228619,null);Aka=new $CLJS.r("metabase.mbql.schema","segment","metabase.mbql.schema/segment",-512121472,null);
vO=new $CLJS.r(null,"relative-datetime","relative-datetime",-1369266491,null);wO=new $CLJS.M("number","!\x3d","number/!\x3d",-673025509);Bka=new $CLJS.M(null,"original","original",-445386197);xO=new $CLJS.M("metabase.mbql.schema","interval","metabase.mbql.schema/interval",-1491935720);yO=new $CLJS.r(null,"abs","abs",1394505050,null);zO=new $CLJS.r(null,"datetime-diff","datetime-diff",-1521323614,null);Cka=new $CLJS.r("metabase.mbql.schema","get-hour","metabase.mbql.schema/get-hour",-809777063,null);
AO=new $CLJS.M("metabase.mbql.schema","cum-count","metabase.mbql.schema/cum-count",-1044004696);Dka=new $CLJS.r(null,"DatetimeDiffUnit","DatetimeDiffUnit",-1310225671,null);$CLJS.BO=new $CLJS.M(null,"date","date",-1463434462);CO=new $CLJS.M(null,"second-clause","second-clause",-461435645);Eka=new $CLJS.r("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",551389774,null);DO=new $CLJS.r(null,"rtrim","rtrim",979195078,null);
EO=new $CLJS.M("metabase.mbql.schema","sqrt","metabase.mbql.schema/sqrt",1829772896);FO=new $CLJS.M("metabase.mbql.schema","get-day-of-week","metabase.mbql.schema/get-day-of-week",-1089141753);GO=new $CLJS.r("metabase.mbql.schema","or","metabase.mbql.schema/or",-1500355255,null);HO=new $CLJS.M("metabase.mbql.schema","Filter","metabase.mbql.schema/Filter",1210108656);IO=new $CLJS.r(null,"CaseOptions","CaseOptions",1989286806,null);JO=new $CLJS.r(null,"or","or",1876275696,null);
Fka=new $CLJS.r("metabase.mbql.schema","ltrim","metabase.mbql.schema/ltrim",52894949,null);Gka=new $CLJS.M(null,"constraints","constraints",422775616);Hka=new $CLJS.r("metabase.mbql.schema","dimension","metabase.mbql.schema/dimension",1500199226,null);KO=new $CLJS.M("metabase.mbql.schema","Field","metabase.mbql.schema/Field",-551724627);Ika=new $CLJS.M(null,"csv-download","csv-download",2141432084);LO=new $CLJS.M("metabase.mbql.schema","get-quarter","metabase.mbql.schema/get-quarter",367107562);
MO=new $CLJS.r(null,"datetime-add","datetime-add",1850134938,null);Jka=new $CLJS.r("metabase.mbql.schema","percentile","metabase.mbql.schema/percentile",-91330436,null);Kka=new $CLJS.r("metabase.mbql.schema","get-month","metabase.mbql.schema/get-month",-2104849029,null);NO=new $CLJS.r("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",-1619862474,null);OO=new $CLJS.r("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",1850085384,null);
PO=new $CLJS.r("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",-1057391587,null);Lka=new $CLJS.r("metabase.mbql.schema","/","metabase.mbql.schema//",2004134796,null);QO=new $CLJS.r(null,"get-quarter","get-quarter",-1326657176,null);Mka=new $CLJS.r("metabase.mbql.schema","-","metabase.mbql.schema/-",-1440604423,null);RO=new $CLJS.r("metabase.mbql.schema","+","metabase.mbql.schema/+",-1802115711,null);Nka=new $CLJS.r("metabase.mbql.schema","*","metabase.mbql.schema/*",1842546528,null);
Oka=new $CLJS.r("metabase.mbql.schema","time","metabase.mbql.schema/time",-1927273576,null);SO=new $CLJS.r(null,"temporal-extract","temporal-extract",-872749364,null);Pka=new $CLJS.r("metabase.mbql.schema","sum","metabase.mbql.schema/sum",-1589890100,null);Qka=new $CLJS.r("metabase.mbql.schema","between","metabase.mbql.schema/between",-302972630,null);Rka=new $CLJS.M(null,"display_name","display_name",-1494335013);$CLJS.TO=new $CLJS.M(null,"snippet-id","snippet-id",1987785841);
$CLJS.UO=new $CLJS.M(null,"database","database",1849087575);VO=new $CLJS.r(null,"NumericExpressionArgOrInterval","NumericExpressionArgOrInterval",-151709337,null);WO=new $CLJS.r(null,"get-day-of-week","get-day-of-week",-644295017,null);Ska=new $CLJS.r("metabase.mbql.schema","starts-with","metabase.mbql.schema/starts-with",1063663591,null);XO=new $CLJS.M("metabase.mbql.schema","floor","metabase.mbql.schema/floor",-1217604600);
YO=new $CLJS.M("metabase.mbql.schema","NumericExpressionArgOrInterval","metabase.mbql.schema/NumericExpressionArgOrInterval",-596692841);$CLJS.ZO=new $CLJS.M(null,"expressions","expressions",255689909);$O=new $CLJS.M("metabase.mbql.schema","ExpressionArg","metabase.mbql.schema/ExpressionArg",372701340);Tka=new $CLJS.M(null,"ad-hoc","ad-hoc",-2033634036);aP=new $CLJS.M("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",912159158);
Uka=new $CLJS.r("metabase.mbql.schema","concat","metabase.mbql.schema/concat",-580718778,null);bP=new $CLJS.M("metabase.mbql.schema","aggregation-options","metabase.mbql.schema/aggregation-options",-936374135);cP=new $CLJS.M("metabase.mbql.schema","log","metabase.mbql.schema/log",1754975861);dP=new $CLJS.r(null,"absolute-datetime","absolute-datetime",1080191062,null);Vka=new $CLJS.r(null,"TimeIntervalOptions","TimeIntervalOptions",1566864026,null);
eP=new $CLJS.r(null,"get-day","get-day",1768100384,null);fP=new $CLJS.M("metabase.mbql.schema","MBQLQuery","metabase.mbql.schema/MBQLQuery",323823775);gP=new $CLJS.M("metabase.mbql.schema","between","metabase.mbql.schema/between",-1943504157);hP=new $CLJS.r(null,"not-empty","not-empty",2029453590,null);$CLJS.iP=new $CLJS.M(null,"native","native",-613060878);Wka=new $CLJS.r("metabase.mbql.schema","variable","metabase.mbql.schema/variable",-1981984686,null);jP=new $CLJS.M(null,"page","page",849072397);
kP=new $CLJS.r(null,"length","length",-2065447907,null);Xka=new $CLJS.M(null,"dashboard","dashboard",-631747508);lP=new $CLJS.r(null,"get-week","get-week",752472178,null);mP=new $CLJS.r(null,"get-month","get-month",1271156796,null);nP=new $CLJS.r(null,"dimension","dimension",-2111181571,null);oP=new $CLJS.M(null,"boolean-expression","boolean-expression",-394924008);pP=new $CLJS.M("metabase.mbql.schema","length","metabase.mbql.schema/length",1810214269);
qP=new $CLJS.r(null,"StringFilterOptions","StringFilterOptions",-847398229,null);rP=new $CLJS.M("metabase.mbql.schema","segment","metabase.mbql.schema/segment",2142314297);sP=new $CLJS.r("metabase.mbql.schema","not","metabase.mbql.schema/not",-113379604,null);Yka=new $CLJS.r("metabase.mbql.schema","now","metabase.mbql.schema/now",665138615,null);tP=new $CLJS.M("metabase.mbql.schema","case","metabase.mbql.schema/case",459951449);uP=new $CLJS.r(null,"substring","substring",-1513569493,null);
Zka=new $CLJS.r("metabase.mbql.schema","avg","metabase.mbql.schema/avg",-1530848906,null);vP=new $CLJS.M(null,"internal","internal",-854870097);$ka=new $CLJS.r("metabase.mbql.schema","regex-match-first","metabase.mbql.schema/regex-match-first",1918122376,null);wP=new $CLJS.M("metabase.mbql.schema","DateTimeExpressionArg","metabase.mbql.schema/DateTimeExpressionArg",1033762257);xP=new $CLJS.M("metabase.mbql.schema","TemplateTag","metabase.mbql.schema/TemplateTag",-1837740311);
yP=new $CLJS.M("metabase.mbql.schema","EqualityComparable","metabase.mbql.schema/EqualityComparable",-72675242);ala=new $CLJS.r("metabase.mbql.schema","substring","metabase.mbql.schema/substring",1829402610,null);zP=new $CLJS.M(null,"more-values-or-fields","more-values-or-fields",-886177554);AP=new $CLJS.r(null,"ltrim","ltrim",-1000166486,null);bla=new $CLJS.r("metabase.mbql.schema","rtrim","metabase.mbql.schema/rtrim",1937815937,null);
cla=new $CLJS.r("metabase.mbql.schema","length","metabase.mbql.schema/length",-844221500,null);BP=new $CLJS.M("metabase.mbql.schema","DatetimeExpression","metabase.mbql.schema/DatetimeExpression",1214423543);CP=new $CLJS.M("metabase.mbql.schema","template-tag","metabase.mbql.schema/template-tag",1296341515);DP=new $CLJS.r("metabase.mbql.schema","coalesce","metabase.mbql.schema/coalesce",-1048570669,null);
dla=new $CLJS.r("metabase.mbql.schema","not-empty","metabase.mbql.schema/not-empty",-2093700401,null);ela=new $CLJS.r("metabase.mbql.schema","lower","metabase.mbql.schema/lower",-313920313,null);EP=new $CLJS.r(null,"desc","desc",-560950005,null);$CLJS.FP=new $CLJS.M(null,"template-tags","template-tags",1853115685);fla=new $CLJS.M(null,"public-question","public-question",629369976);gla=new $CLJS.r("metabase.mbql.schema","temporal-extract","metabase.mbql.schema/temporal-extract",-1825197161,null);
hla=new $CLJS.M(null,"binary","binary",-1802232288);GP=new $CLJS.r(null,"time-interval","time-interval",-1949813754,null);ila=new $CLJS.M(null,"userland-query?","userland-query?",-123699383);$CLJS.HP=new $CLJS.M(null,"source-query","source-query",198004422);IP=new $CLJS.M("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-1932527842);jla=new $CLJS.r(null,"SegmentID","SegmentID",-1773652704,null);
kla=new $CLJS.r("metabase.mbql.schema","get-minute","metabase.mbql.schema/get-minute",-2138696889,null);JP=new $CLJS.M("metabase.mbql.schema","UnnamedAggregation","metabase.mbql.schema/UnnamedAggregation",-196705529);KP=new $CLJS.M("metabase.mbql.schema","share","metabase.mbql.schema/share",337132634);lla=new $CLJS.M(null,"executed-by","executed-by",-739811161);mla=new $CLJS.r("metabase.mbql.schema","aggregation","metabase.mbql.schema/aggregation",977707224,null);
nla=new $CLJS.r("metabase.mbql.schema","median","metabase.mbql.schema/median",2055093571,null);LP=new $CLJS.M(null,"amount","amount",364489504);MP=new $CLJS.r(null,"percentile","percentile",1039342775,null);NP=new $CLJS.M("metabase.mbql.schema","metric","metabase.mbql.schema/metric",-610527242);OP=new $CLJS.r(null,"metabase.mbql.schema","metabase.mbql.schema",-408168902,null);PP=new $CLJS.M("metabase.mbql.schema","ceil","metabase.mbql.schema/ceil",1249807143);
QP=new $CLJS.r(null,"NumericExpressionArg","NumericExpressionArg",-1537445195,null);RP=new $CLJS.r(null,"trim","trim",-1880116002,null);ola=new $CLJS.r(null,"RawTimeLiteral","RawTimeLiteral",-2137224789,null);SP=new $CLJS.r(null,"coalesce","coalesce",-1999813740,null);TP=new $CLJS.M("metabase.mbql.schema","sum","metabase.mbql.schema/sum",1064545669);pla=new $CLJS.M("metabase.mbql.schema","DateTimeValue","metabase.mbql.schema/DateTimeValue",1983295553);
UP=new $CLJS.M("string","\x3d","string/\x3d",983744235);qla=new $CLJS.M(null,"skip-results-metadata?","skip-results-metadata?",251010463);rla=new $CLJS.r("metabase.mbql.schema","asc","metabase.mbql.schema/asc",1338042063,null);sla=new $CLJS.r("metabase.mbql.schema","inside","metabase.mbql.schema/inside",-291996315,null);VP=new $CLJS.r(null,"StringExpressionArg","StringExpressionArg",-1131357258,null);WP=new $CLJS.M(null,"value-or-field","value-or-field",-1387286309);
XP=new $CLJS.r(null,"FieldOrExpressionDef","FieldOrExpressionDef",-788269541,null);tla=new $CLJS.M(null,"lat-min","lat-min",1630784161);YP=new $CLJS.r(null,"ArithmeticDateTimeUnit","ArithmeticDateTimeUnit",-838492507,null);ZP=new $CLJS.r(null,"inside","inside",-681932758,null);ula=new $CLJS.r("metabase.mbql.schema","stddev","metabase.mbql.schema/stddev",1735459125,null);$P=new $CLJS.M("metabase.mbql.schema","NumericExpression","metabase.mbql.schema/NumericExpression",1794540739);
vla=new $CLJS.r("metabase.mbql.schema","convert-timezone","metabase.mbql.schema/convert-timezone",1628930633,null);aQ=new $CLJS.r(null,"starts-with","starts-with",2007034536,null);wla=new $CLJS.M(null,"process-viz-settings?","process-viz-settings?",-173592315);xla=new $CLJS.r("metabase.mbql.schema","power","metabase.mbql.schema/power",-517905321,null);$CLJS.bQ=new $CLJS.M(null,"card-id","card-id",-1770060179);cQ=new $CLJS.M(null,"variadic","variadic",882626057);
dQ=new $CLJS.r(null,"upper","upper",1886775433,null);eQ=new $CLJS.M(null,"field-or-expression","field-or-expression",-1409494368);fQ=new $CLJS.M("metabase.mbql.schema","Aggregation","metabase.mbql.schema/Aggregation",746170880);gQ=new $CLJS.r(null,"optional","optional",-600484260,null);yla=new $CLJS.r("metabase.mbql.schema","exp","metabase.mbql.schema/exp",451780488,null);hQ=new $CLJS.M("metabase.mbql.schema","FieldOptions","metabase.mbql.schema/FieldOptions",-2115604229);
iQ=new $CLJS.M(null,"sugar","sugar",-73788488);zla=new $CLJS.M(null,"lat-max","lat-max",841568226);jQ=new $CLJS.r(null,"power","power",702679448,null);kQ=new $CLJS.r("metabase.mbql.schema","and","metabase.mbql.schema/and",1553856071,null);lQ=new $CLJS.M("metabase.mbql.schema","replace","metabase.mbql.schema/replace",-371168701);mQ=new $CLJS.M("metabase.mbql.schema","NumericExpressionArg","metabase.mbql.schema/NumericExpressionArg",-1965874261);
nQ=new $CLJS.M("metabase.mbql.schema","Fields","metabase.mbql.schema/Fields",1872461942);oQ=new $CLJS.r(null,"median","median",-2084869638,null);pQ=new $CLJS.M("metabase.mbql.schema","ParameterType","metabase.mbql.schema/ParameterType",1268586893);qQ=new $CLJS.r(null,"cum-sum","cum-sum",-589533420,null);Ala=new $CLJS.r("metabase.mbql.schema","trim","metabase.mbql.schema/trim",1991053191,null);rQ=new $CLJS.M(null,"y","y",-1757859776);sQ=new $CLJS.r(null,"aggregation","aggregation",-1056959073,null);
$CLJS.tQ=new $CLJS.M(null,"binning","binning",1709835866);uQ=new $CLJS.r(null,"TimezoneId","TimezoneId",-1406242397,null);vQ=new $CLJS.M("metabase.mbql.schema","\x3e","metabase.mbql.schema/\x3e",1034573295);wQ=new $CLJS.M("metabase.mbql.schema","\x3c","metabase.mbql.schema/\x3c",1597044182);xQ=new $CLJS.M("metabase.mbql.schema","\x3d","metabase.mbql.schema/\x3d",209553857);yQ=new $CLJS.r(null,"Reference","Reference",2024574086,null);zQ=new $CLJS.M(null,"b","b",1482224470);
AQ=new $CLJS.M(null,"a","a",-2123407586);BQ=new $CLJS.M("metabase.mbql.schema","!\x3d","metabase.mbql.schema/!\x3d",1846805017);CQ=new $CLJS.r(null,"replace","replace",853943757,null);DQ=new $CLJS.M("metabase.mbql.schema","cum-sum","metabase.mbql.schema/cum-sum",576093708);Bla=new $CLJS.r("metabase.mbql.schema","upper","metabase.mbql.schema/upper",993691634,null);EQ=new $CLJS.M("metabase.mbql.schema","/","metabase.mbql.schema//",363603269);
FQ=new $CLJS.M("metabase.mbql.schema","-","metabase.mbql.schema/-",1213831346);GQ=new $CLJS.M("metabase.mbql.schema","*","metabase.mbql.schema/*",202015001);HQ=new $CLJS.M("metabase.mbql.schema","+","metabase.mbql.schema/+",852320058);IQ=new $CLJS.r(null,"segment","segment",675610331,null);Cla=new $CLJS.r("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",1436319181,null);JQ=new $CLJS.M("metabase.mbql.schema","field","metabase.mbql.schema/field",-73678671);
KQ=new $CLJS.r("metabase.mbql.schema","\x3e\x3d","metabase.mbql.schema/\x3e\x3d",1977236849,null);LQ=new $CLJS.M("metabase.mbql.schema","count","metabase.mbql.schema/count",-962464844);MQ=new $CLJS.M("string","starts-with","string/starts-with",1266861170);Dla=new $CLJS.r(null,"TemporalExtractUnit","TemporalExtractUnit",1691102108,null);$CLJS.NQ=new $CLJS.M(null,"order-by","order-by",1527318070);OQ=new $CLJS.M(null,"advanced-math-expressions","advanced-math-expressions",1076581757);
Ela=new $CLJS.r("metabase.mbql.schema","is-null","metabase.mbql.schema/is-null",637054412,null);Fla=new $CLJS.M("alias","escaped-\x3eoriginal","alias/escaped-\x3eoriginal",-1334024182);$CLJS.PQ=new $CLJS.M(null,"condition","condition",1668437652);QQ=new $CLJS.M("string","does-not-contain","string/does-not-contain",-1536178964);Gla=new $CLJS.M(null,"report-timezone","report-timezone",-2053796389);RQ=new $CLJS.r("metabase.mbql.schema","\x3c\x3d","metabase.mbql.schema/\x3c\x3d",-2098137664,null);
Hla=new $CLJS.M(null,"card-name","card-name",-2035606807);SQ=new $CLJS.M("metabase.mbql.schema","value","metabase.mbql.schema/value",772933314);Ila=new $CLJS.r("metabase.mbql.schema","sum-where","metabase.mbql.schema/sum-where",969078380,null);Jla=new $CLJS.r("metabase.mbql.schema","does-not-contain","metabase.mbql.schema/does-not-contain",-1742276611,null);TQ=new $CLJS.r(null,"log","log",45015523,null);Kla=new $CLJS.r("metabase.mbql.schema","abs","metabase.mbql.schema/abs",1792880149,null);
UQ=new $CLJS.r(null,"time","time",-1268547887,null);$CLJS.VQ=new $CLJS.M(null,"database_type","database_type",-54700895);WQ=new $CLJS.r(null,"EqualityComparable","EqualityComparable",-2076258858,null);XQ=new $CLJS.r(null,"get-minute","get-minute",-1186349426,null);$CLJS.YQ=new $CLJS.M(null,"clause","clause",1479668060);$CLJS.ZQ=new $CLJS.M(null,"expression-name","expression-name",-1798450709);$CLJS.$Q=new $CLJS.M("date","all-options","date/all-options",549325958);
Lla=new $CLJS.r("metabase.mbql.schema","relative-datetime","metabase.mbql.schema/relative-datetime",1965492610,null);Mla=new $CLJS.M(null,"embedded-json-download","embedded-json-download",-1980927033);aR=new $CLJS.M("location","state","location/state",-114378652);bR=new $CLJS.r(null,"datetime-subtract","datetime-subtract",1915839224,null);Nla=new $CLJS.M(null,"aggregation-clause-index","aggregation-clause-index",-1510292261);Ola=new $CLJS.M(null,"lon-field","lon-field",517872067);
cR=new $CLJS.M("metabase.mbql.schema","TemporalLiteral","metabase.mbql.schema/TemporalLiteral",-904081026);dR=new $CLJS.r(null,"DateTimeExpressionArg","DateTimeExpressionArg",-1507653823,null);eR=new $CLJS.M(null,"numeric","numeric",-1495594714);fR=new $CLJS.r(null,"variable","variable",1359185035,null);gR=new $CLJS.r(null,"lower","lower",-1534114948,null);Pla=new $CLJS.M(null,"embedded-xlsx-download","embedded-xlsx-download",-1105087722);
hR=new $CLJS.M("metabase.mbql.schema","concat","metabase.mbql.schema/concat",2073716991);iR=new $CLJS.M("metabase.mbql.schema","get-week","metabase.mbql.schema/get-week",-204212346);$CLJS.jR=new $CLJS.M(null,"limit","limit",-1355822363);kR=new $CLJS.M("metabase.mbql.schema","round","metabase.mbql.schema/round",-1325713739);lR=new $CLJS.r(null,"ExtractWeekMode","ExtractWeekMode",397218079,null);Qla=new $CLJS.M(null,"pulse","pulse",-244494476);var mR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.rD],null),nR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.CD],null),oR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.nD],null),pR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iD],null),qR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.vD],null),rR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.BD],null),Rla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XJ],null),Sla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.QJ],null),sR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.LH],null),tR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.PH],null),uR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.GH],null),vR=new $CLJS.Rg(null,new $CLJS.h(null,12,[$CLJS.bi,null,$CLJS.Lk,null,$CLJS.Ti,null,$CLJS.Oh,null,$CLJS.pi,null,$CLJS.Zi,null,$CLJS.Dj,null,$CLJS.ij,null,$CLJS.Wh,null,$CLJS.hj,null,$CLJS.ci,null,$CLJS.ki,null],null),null),wR=new $CLJS.Rg(null,new $CLJS.h(null,7,[$CLJS.Jk,null,$CLJS.VF,null,$CLJS.Oh,null,$CLJS.fi,null,$CLJS.YF,null,$CLJS.Pi,null,$CLJS.Dk,null],null),null),
xR=$CLJS.Ws.g(vR,wR),Tla=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.us,"date bucketing unit"],null)],null),vR),Ula=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.us,"time bucketing unit"],null)],null),wR),yR=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.us,"datetime bucketing unit"],null)],null),xR),zR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.kI],null),Vla=new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,
1,[$CLJS.us,"temporal extract unit"],null),$CLJS.ZF,$CLJS.ki,$CLJS.Dj,$CLJS.aI,$CLJS.oI,$CLJS.pI,$CLJS.ij,$CLJS.pi,$CLJS.fi,$CLJS.Pi,$CLJS.WF],null),Wla=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.us,"datetime-diff unit"],null),$CLJS.VF,$CLJS.Dk,$CLJS.Jk,$CLJS.Lk,$CLJS.Ti,$CLJS.Zi,$CLJS.bi,$CLJS.Wh],null),AR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.us,"temporal-extract week extraction mode"],null),$CLJS.VH,$CLJS.bI,$CLJS.fI],null),BR=new $CLJS.P(null,
10,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.us,"relative-datetime unit"],null),$CLJS.Oh,$CLJS.Dk,$CLJS.Jk,$CLJS.Lk,$CLJS.Ti,$CLJS.Zi,$CLJS.bi,$CLJS.Wh],null),IR,Yla,QR,RR,SR,TR,UR,VR,WR,JT,$la,KT,ama,bma,LT,cma,dma,ema;$CLJS.Y(TL,cL($CLJS.$A,$CLJS.H(["n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Qu],null),$CLJS.qr],null),"unit",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,BR],null)])));var CR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TL],null);
$CLJS.Y(xO,cL($CLJS.ZA,$CLJS.H(["n",$CLJS.qr,"unit",BR])));var Xla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xO],null);
$CLJS.Y(nM,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"valid :absolute-datetime clause",$CLJS.xi,function(a){if($CLJS.Ra(bL($CLJS.ZH,a)))a=mM;else{a=$CLJS.dd(a);var b=$CLJS.YK(sR);a=b.h?b.h(a):b.call(null,a);a=$CLJS.n(a)?$CLJS.BO:$CLJS.cI}return a}],null),new $CLJS.P(null,2,5,$CLJS.Q,[mM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"not an :absolute-datetime clause"],null),$CLJS.Pe(!1)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.BO,cL($CLJS.ZH,
$CLJS.H(["date",sR,"unit",Tla]))],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cI,cL($CLJS.ZH,$CLJS.H(["datetime",tR,"unit",yR]))],null)],null));var DR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nM],null);$CLJS.Y(CM,cL($CLJS.Fx,$CLJS.H(["time",uR,"unit",Ula])));var ER=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CM],null),FR=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.us,"date or datetime literal"],null),DR,tR,sR],null);
$CLJS.Y(nL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.us,"time literal"],null),ER,uR],null));$CLJS.Y(cR,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.us,"temporal literal"],null),FR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nL],null)],null));var GR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cR],null);
$CLJS.Y(pla,dL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof DR?new $CLJS.Cc(function(){return DR},$CLJS.kd(Qja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,vP,$CLJS.Si,$CLJS.Oj,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),dP,"metabase/mbql/schema.cljc",69,$CLJS.ZH,1,!0,168,168,$CLJS.Gc,"Schema for an `:absolute-datetime` clause.",$CLJS.n(DR)?DR.H:null])):null));return $CLJS.n(a)?a:dP}(),DR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof CR?new $CLJS.Cc(function(){return CR},$CLJS.kd(Lla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,
$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),vO,"metabase/mbql/schema.cljc",29,$CLJS.$A,1,131,131,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.$A,$CLJS.Kk,$CLJS.V($CLJS.pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.Qu],null),$CLJS.qr],null)),$CLJS.Bk,$CLJS.V($CLJS.pj,$CLJS.V(gQ,TN))],null),$CLJS.Gc,"Schema for a valid relative-datetime clause.",
$CLJS.n(CR)?CR.H:null])):null));return $CLJS.n(a)?a:vO}(),CR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof ER?new $CLJS.Cc(function(){return ER},$CLJS.kd(Oka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,vP,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UQ,"metabase/mbql/schema.cljc",27,$CLJS.Fx,1,!0,175,175,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fx,$CLJS.Fx,$CLJS.V($CLJS.pj,ola),$CLJS.Bk,$CLJS.V($CLJS.pj,hka)],null),$CLJS.Gc,"Schema for a valid time clause.",$CLJS.n(ER)?ER.H:null])):null));return $CLJS.n(a)?a:UQ}(),ER],null)])));
$CLJS.Y(SQ,cL($CLJS.ej,$CLJS.H(["value",$CLJS.vr,"type-info",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.VQ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,mR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cC,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,nR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.dr,oR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bk,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,yR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,mR],null)],null)],null)],null)])));var HR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SQ],null);$CLJS.Y(CN,cL($CLJS.qA,$CLJS.H(["expression-name",mR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,$CLJS.Aj],null)])));
IR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CN],null);
Yla=function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"You must specify :num-bins when using the :num-bins strategy."],null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.ED);c=$CLJS.J.g(c,$CLJS.kD);return $CLJS.E.g(b,$CLJS.kD)?c:!0}],null)],null)}(function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"You must specify :bin-width when using the :bin-width strategy."],
null),function(b){var c=$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.ED);c=$CLJS.J.g(c,$CLJS.DD);return $CLJS.E.g(b,$CLJS.DD)?c:!0}],null)],null)}(new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.us,"binning options"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ED,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.us,"binning strategy"],null),$CLJS.kD,$CLJS.DD,$CLJS.Oh],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),pR],null),new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.DD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.Zk,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"bin width must be \x3e\x3d 0."],null),$CLJS.Oe($CLJS.tl)],null)],null)],null)],null)));$CLJS.JR=function JR(a){switch(arguments.length){case 1:return JR.h(arguments[0]);case 2:return JR.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};
$CLJS.JR.h=function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.tA);b=$CLJS.J.g(b,$CLJS.sF);return $CLJS.JR.g(a,b)};$CLJS.JR.g=function(a,b){a=$CLJS.n($CLJS.n(b)?a:b)?$CLJS.Dz(a,$CLJS.ji)?vR:$CLJS.Dz(a,$CLJS.Nj)?wR:$CLJS.Dz(a,$CLJS.dj)?xR:null:null;return $CLJS.n(a)?$CLJS.Cd(a,b):!0};$CLJS.JR.A=2;
$CLJS.Y(hQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.us,"field options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tA,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,nR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,rR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.sF,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,yR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,mR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tQ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,Yla],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"Invalid :temporal-unit for the specified :base-type."],null),$CLJS.JR],null)],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"Found :binning keys at the top level of :field options. binning-related options belong under the :binning key."],null),$CLJS.Oe($CLJS.ED)],null)],null));
$CLJS.Y(JQ,function(a){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,":field clauses using a string field name must specify :base-type."],null),function(b){$CLJS.I(b,0,null);var c=$CLJS.I(b,1,null);b=$CLJS.I(b,2,null);b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.tA);return"string"===typeof c?b:!0}],null)],null)}(cL($CLJS.mF,$CLJS.H(["id-or-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,rR,mR],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hQ],null)],null)]))));var KR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JQ],null);
$CLJS.Y(KO,dL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof IR?new $CLJS.Cc(function(){return IR},$CLJS.kd(OM,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),yM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.ZQ,$CLJS.V($CLJS.pj,VL),$CLJS.Ni,$CLJS.V($CLJS.pj,$CLJS.V(gQ,$CLJS.Aj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(IR)?IR.H:null])):null));return $CLJS.n(a)?a:yM}(),IR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof KR?new $CLJS.Cc(function(){return KR},
$CLJS.kd(fN,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.cq,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,$CLJS.di,$CLJS.Cj,$CLJS.Fj],["0.39.0",$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gM,"metabase/mbql/schema.cljc",51,$CLJS.mF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(KR)?KR.H:null])):null));return $CLJS.n(a)?a:gM}(),KR],null)])));
$CLJS.LR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KO],null);$CLJS.Y(iL,cL($CLJS.bF,$CLJS.H(["aggregation-clause-index",$CLJS.qr,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,$CLJS.Aj],null)])));var MR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iL],null);
$CLJS.Y(PL,dL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof MR?new $CLJS.Cc(function(){return MR},$CLJS.kd(mla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),sQ,"metabase/mbql/schema.cljc",23,$CLJS.bF,1,418,418,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.bF,Nla,$CLJS.V($CLJS.pj,$CLJS.qr),$CLJS.Ni,$CLJS.V($CLJS.pj,$CLJS.V(gQ,$CLJS.Aj))],null),$CLJS.Gc,"Schema for a valid aggregation clause.",$CLJS.n(MR)?MR.H:null])):null));return $CLJS.n(a)?a:sQ}(),MR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof IR?new $CLJS.Cc(function(){return IR},
$CLJS.kd(OM,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yM,"metabase/mbql/schema.cljc",60,$CLJS.qA,1,249,249,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.qA,$CLJS.ZQ,$CLJS.V($CLJS.pj,VL),
$CLJS.Ni,$CLJS.V($CLJS.pj,$CLJS.V(gQ,$CLJS.Aj))],null),$CLJS.Gc,"Schema for a valid expression clause.",$CLJS.n(IR)?IR.H:null])):null));return $CLJS.n(a)?a:yM}(),IR],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof KR?new $CLJS.Cc(function(){return KR},$CLJS.kd(fN,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.cq,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,
$CLJS.di,$CLJS.Cj,$CLJS.Fj],["0.39.0",$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gM,"metabase/mbql/schema.cljc",51,$CLJS.mF,1,382,382,$CLJS.Gc,"Schema for a `:field` clause.",$CLJS.n(KR)?KR.H:null])):null));return $CLJS.n(a)?a:gM}(),KR],null)])));
var NR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PL],null),OR=new $CLJS.Rg(null,new $CLJS.h(null,11,[$CLJS.ks,null,$CLJS.DF,null,$CLJS.EE,null,$CLJS.JF,null,$CLJS.LE,null,$CLJS.pF,null,$CLJS.VE,null,$CLJS.qs,null,$CLJS.BF,null,$CLJS.oF,null,$CLJS.KF,null],null),null),PR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ML],null);
$CLJS.Y(pL,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.xi,function(a){return"string"===typeof a?$CLJS.vj:$CLJS.n(bL(OR,a))?IL:$CLJS.n(bL($CLJS.ej,a))?$CLJS.ej:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,$CLJS.vj],null),new $CLJS.P(null,2,5,$CLJS.Q,[IL,PR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,HR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$CLJS.LR],null)],null));QR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pL],null);
RR=new $CLJS.Rg(null,new $CLJS.h(null,26,[$CLJS.HE,null,$CLJS.nI,null,$CLJS.Kq,null,$CLJS.cB,null,$CLJS.iI,null,$CLJS.$E,null,$CLJS.$r,null,$CLJS.DE,null,$CLJS.lI,null,$CLJS.aB,null,$CLJS.pF,null,$CLJS.GF,null,$CLJS.jI,null,$CLJS.rF,null,$CLJS.JE,null,$CLJS.XH,null,$CLJS.Jq,null,$CLJS.ME,null,$CLJS.VE,null,$CLJS.RH,null,$CLJS.Pw,null,$CLJS.YH,null,$CLJS.gI,null,$CLJS.YA,null,$CLJS.IF,null,$CLJS.kF,null],null),null);
SR=new $CLJS.Rg(null,new $CLJS.h(null,9,[$CLJS.Gq,null,$CLJS.Aq,null,$CLJS.er,null,$CLJS.gr,null,$CLJS.Cq,null,$CLJS.CF,null,$CLJS.lr,null,$CLJS.Hq,null,$CLJS.Eq,null],null),null);TR=new $CLJS.Rg(null,new $CLJS.h(null,17,[$CLJS.si,null,$CLJS.AF,null,$CLJS.vF,null,$CLJS.nF,null,$CLJS.FF,null,$CLJS.HF,null,$CLJS.RE,null,$CLJS.YE,null,$CLJS.uL,null,$CLJS.SE,null,$CLJS.zF,null,$CLJS.tF,null,$CLJS.Bj,null,$CLJS.Rw,null,$CLJS.gF,null,$CLJS.hF,null,$CLJS.yF,null],null),null);
UR=new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.dI,null,$CLJS.TH,null,$CLJS.eB,null,$CLJS.Jq,null,$CLJS.bB,null],null),null);VR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$P],null);WR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AM],null);$CLJS.YR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BP],null);$CLJS.ZR=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fQ],null);
$CLJS.Y(mQ,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"numeric expression argument",$CLJS.xi,function(a){return"number"===typeof a?$CLJS.Qs:$CLJS.n(bL(RR,a))?dO:$CLJS.n(bL(TR,a))?$CLJS.bF:$CLJS.n(bL($CLJS.ej,a))?$CLJS.ej:$CLJS.mF}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.Zk],null),new $CLJS.P(null,2,5,$CLJS.Q,[dO,VR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bF,$CLJS.ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,HR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mF,
$CLJS.LR],null)],null));var $R=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mQ],null);
$CLJS.Y(wP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"datetime expression argument",$CLJS.xi,function(a){return $CLJS.n(bL(TR,a))?$CLJS.bF:$CLJS.n(bL($CLJS.ej,a))?$CLJS.ej:$CLJS.n(bL(UR,a))?HM:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bF,$CLJS.ZR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,HR],null),new $CLJS.P(null,2,5,$CLJS.Q,[HM,$CLJS.YR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,FR,$CLJS.LR],null)],null)],null));
var aS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wP],null);
$CLJS.Y($O,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"expression argument",$CLJS.xi,function(a){return"number"===typeof a?$CLJS.Qs:$CLJS.xd(a)?$CLJS.ur:$CLJS.n(bL(SR,a))?oP:$CLJS.n(bL(RR,a))?dO:$CLJS.n(bL(UR,a))?HM:"string"===typeof a?$CLJS.vj:$CLJS.n(bL(OR,a))?IL:$CLJS.n(bL($CLJS.ej,a))?$CLJS.ej:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,$CLJS.Zk],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ur,$CLJS.ur],null),new $CLJS.P(null,2,5,$CLJS.Q,[oP,WR],null),new $CLJS.P(null,
2,5,$CLJS.Q,[dO,VR],null),new $CLJS.P(null,2,5,$CLJS.Q,[HM,$CLJS.YR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,$CLJS.vj],null),new $CLJS.P(null,2,5,$CLJS.Q,[IL,PR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,HR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$CLJS.LR],null)],null));var bS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$O],null);$CLJS.Y(YO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.us,"numeric expression arg or interval"],null),Xla,$R],null));
var cS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,YO],null);$CLJS.Y(AL,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"int greater than zero or numeric expression",$CLJS.xi,function(a){return"number"===typeof a?$CLJS.Qs:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Qs,pR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,VR],null)],null));var Zla=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AL],null);
$CLJS.Y(IM,cL($CLJS.pF,$CLJS.H(["a",bS,"b",bS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,bS],null)])));var dS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IM],null);$CLJS.Y(mL,cL($CLJS.EE,$CLJS.H(["s",QR,"start",Zla,"length",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,$R],null)])));var eS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mL],null);$CLJS.Y(pP,cL($CLJS.Pw,$CLJS.H(["s",QR])));var fS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pP],null);$CLJS.Y(OL,cL($CLJS.BF,$CLJS.H(["s",QR])));
var gS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,OL],null);$CLJS.Y(SL,cL($CLJS.KF,$CLJS.H(["s",QR])));var hS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,SL],null);$CLJS.Y(kM,cL($CLJS.DF,$CLJS.H(["s",QR])));var iS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kM],null);$CLJS.Y(WM,cL($CLJS.ks,$CLJS.H(["s",QR])));var jS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,WM],null);$CLJS.Y(FL,cL($CLJS.qs,$CLJS.H(["s",QR])));var kS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FL],null);
$CLJS.Y(lQ,cL($CLJS.JF,$CLJS.H(["s",QR,"match",$CLJS.vj,"replacement",$CLJS.vj])));var lS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lQ],null);$CLJS.Y(hR,cL($CLJS.LE,$CLJS.H(["a",QR,"b",QR,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,QR],null)])));var nS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hR],null);$CLJS.Y(LN,cL($CLJS.oF,$CLJS.H(["s",QR,"pattern",$CLJS.vj])));var oS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LN],null);
$CLJS.Y(HQ,cL($CLJS.Jq,$CLJS.H(["x",cS,"y",cS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,cS],null)])));var pS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HQ],null);$CLJS.Y(FQ,cL($CLJS.$r,$CLJS.H(["x",$R,"y",cS,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,cS],null)])));var qS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FQ],null);$CLJS.Y(EQ,cL($CLJS.GF,$CLJS.H(["x",$R,"y",$R,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,$R],null)])));var rS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EQ],null);
$CLJS.Y(GQ,cL($CLJS.Kq,$CLJS.H(["x",$R,"y",$R,"more",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,$R],null)])));var sS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,GQ],null);$CLJS.Y(XO,cL($CLJS.kF,$CLJS.H(["x",$R])));var tS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XO],null);$CLJS.Y(PP,cL($CLJS.HE,$CLJS.H(["x",$R])));var uS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,PP],null);$CLJS.Y(kR,cL($CLJS.rF,$CLJS.H(["x",$R])));var vS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kR],null);$CLJS.Y(bO,cL($CLJS.ME,$CLJS.H(["x",$R])));
var wS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bO],null);$CLJS.Y(hO,cL($CLJS.JE,$CLJS.H(["x",$R,"y",$R])));var xS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hO],null);$CLJS.Y(EO,cL($CLJS.$E,$CLJS.H(["x",$R])));var yS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,EO],null);$CLJS.Y(BL,cL($CLJS.DE,$CLJS.H(["x",$R])));var zS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BL],null);$CLJS.Y(cP,cL($CLJS.IF,$CLJS.H(["x",$R])));var AS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cP],null);
$CLJS.Y(aM,cL($CLJS.YA,$CLJS.H(["datetime-x",aS,"datetime-y",aS,"unit",Wla])));var BS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aM],null);$CLJS.Y(sM,cL($CLJS.cB,$CLJS.H(["datetime",aS,"unit",Vla,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,AR],null)])));var CS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sM],null);$CLJS.Y(jO,cL($CLJS.YH,$CLJS.H(["date",aS])));var DS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jO],null);$CLJS.Y(LO,cL($CLJS.nI,$CLJS.H(["date",aS])));
var ES=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LO],null);$CLJS.Y(oL,cL($CLJS.RH,$CLJS.H(["date",aS])));var FS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,oL],null);$CLJS.Y(iR,cL($CLJS.aB,$CLJS.H(["date",aS,"mode",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,AR],null)])));var GS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,iR],null);$CLJS.Y(wN,cL($CLJS.gI,$CLJS.H(["date",aS])));var HS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wN],null);$CLJS.Y(FO,cL($CLJS.jI,$CLJS.H(["date",aS])));
var IS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,FO],null);$CLJS.Y(XL,cL($CLJS.lI,$CLJS.H(["datetime",aS])));var JS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,XL],null);$CLJS.Y(BN,cL($CLJS.iI,$CLJS.H(["datetime",aS])));var KS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BN],null);$CLJS.Y(QL,cL($CLJS.XH,$CLJS.H(["datetime",aS])));var LS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QL],null);$CLJS.Y(gN,cL($CLJS.TH,$CLJS.H(["datetime",aS,"to",zR,"from",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,zR],null)])));
var MS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gN],null),NS=new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.us,"datetime arithmetic unit"],null),$CLJS.YF,$CLJS.VF,$CLJS.Dk,$CLJS.Jk,$CLJS.Lk,$CLJS.Ti,$CLJS.Zi,$CLJS.bi,$CLJS.Wh],null);$CLJS.Y(lL,cL($CLJS.bB,$CLJS.H(["datetime",aS,"amount",$R,"unit",NS])));var OS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lL],null);
$CLJS.Y(JL,function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return cL(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)}($CLJS.dI));var PS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,JL],null);$CLJS.Y(kL,cL($CLJS.eB,$CLJS.H(["datetime",aS,"amount",$R,"unit",NS])));var QS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kL],null);
$CLJS.Y(BP,dL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof pS?new $CLJS.Cc(function(){return pS},$CLJS.kd(RO,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FN,"metabase/mbql/schema.cljc",51,$CLJS.Jq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Jq,$CLJS.AD,$CLJS.V($CLJS.pj,VO),rQ,$CLJS.V($CLJS.pj,VO),JM,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,VO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(pS)?pS.H:null])):null));return $CLJS.n(a)?a:FN}(),pS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof OS?new $CLJS.Cc(function(){return OS},
$CLJS.kd(yka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$M,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MO,"metabase/mbql/schema.cljc",67,$CLJS.bB,1,703,703,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.bB,$CLJS.cI,$CLJS.V($CLJS.pj,dR),LP,$CLJS.V($CLJS.pj,
QP),$CLJS.Bk,$CLJS.V($CLJS.pj,YP)],null),$CLJS.Gc,"Schema for a valid datetime-add clause.",$CLJS.n(OS)?OS.H:null])):null));return $CLJS.n(a)?a:MO}(),OS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof QS?new $CLJS.Cc(function(){return QS},$CLJS.kd(rja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,
$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$M,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),bR,"metabase/mbql/schema.cljc",72,$CLJS.eB,1,710,710,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.eB,$CLJS.cI,$CLJS.V($CLJS.pj,dR),LP,$CLJS.V($CLJS.pj,QP),$CLJS.Bk,$CLJS.V($CLJS.pj,YP)],null),$CLJS.Gc,"Schema for a valid datetime-subtract clause.",$CLJS.n(QS)?QS.H:null])):null));
return $CLJS.n(a)?a:bR}(),QS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof MS?new $CLJS.Cc(function(){return MS},$CLJS.kd(vla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.TH,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GM,"metabase/mbql/schema.cljc",71,$CLJS.TH,1,695,695,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.TH,$CLJS.cI,$CLJS.V($CLJS.pj,dR),qka,$CLJS.V($CLJS.pj,uQ),vja,$CLJS.V($CLJS.pj,$CLJS.V(gQ,uQ))],null),$CLJS.Gc,"Schema for a valid convert-timezone clause.",$CLJS.n(MS)?MS.H:null])):null));return $CLJS.n(a)?a:GM}(),MS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&
"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof PS?new $CLJS.Cc(function(){return PS},$CLJS.kd(Yka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.dI,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kO,"metabase/mbql/schema.cljc",
45,$CLJS.dI,1,708,708,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.dI],null),$CLJS.Gc,"Schema for a valid now clause.",$CLJS.n(PS)?PS.H:null])):null));return $CLJS.n(a)?a:kO}(),PS],null)])));$CLJS.RS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,HO],null);$CLJS.Y(qL,cL($CLJS.lr,$CLJS.H(["first-clause",$CLJS.RS,"second-clause",$CLJS.RS,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,$CLJS.RS],null)])));var SS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,qL],null);
$CLJS.Y(cN,cL($CLJS.er,$CLJS.H(["first-clause",$CLJS.RS,"second-clause",$CLJS.RS,"other-clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,$CLJS.RS],null)])));var TS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cN],null);$CLJS.Y(LL,cL($CLJS.gr,$CLJS.H(["clause",$CLJS.RS])));
var US=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LL],null),VS=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,3,[$CLJS.us,":field or :expression reference or :relative-datetime",$CLJS.Fs,$CLJS.Pe(":field or :expression reference or :relative-datetime"),$CLJS.xi,function(a){return $CLJS.n(bL($CLJS.$A,a))?$CLJS.$A:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.$A,CR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$CLJS.LR],null)],null);
$CLJS.Y(yP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.dr,new $CLJS.h(null,1,[$CLJS.us,"equality comparable"],null),new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.er,$CLJS.ur,$CLJS.Zk,$CLJS.vj,GR,VS,bS,HR],null)],null));var WS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yP],null);
$CLJS.Y(QN,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"order comparable",$CLJS.xi,function(a){return $CLJS.n(bL($CLJS.ej,a))?$CLJS.ej:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ej,HR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.er,$CLJS.Zk,$CLJS.vj,GR,bS,VS],null)],null)],null));var XS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,QN],null);
$CLJS.Y(xQ,cL($CLJS.Hq,$CLJS.H(["field",WS,"value-or-field",WS,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,WS],null)])));var YS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xQ],null);$CLJS.Y(BQ,cL($CLJS.CF,$CLJS.H(["field",WS,"value-or-field",WS,"more-values-or-fields",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.yi,WS],null)])));var ZS=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,BQ],null);$CLJS.Y(wQ,cL($CLJS.Eq,$CLJS.H(["field",XS,"value-or-field",XS])));
var $S=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wQ],null);$CLJS.Y(vQ,cL($CLJS.Aq,$CLJS.H(["field",XS,"value-or-field",XS])));var aT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,vQ],null);$CLJS.Y(lM,cL($CLJS.Gq,$CLJS.H(["field",XS,"value-or-field",XS])));var bT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,lM],null);$CLJS.Y(rM,cL($CLJS.Cq,$CLJS.H(["field",XS,"value-or-field",XS])));var cT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rM],null);$CLJS.Y(gP,cL($CLJS.OE,$CLJS.H(["field",XS,"min",XS,"max",XS])));
var dT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,gP],null);$CLJS.Y(IP,cL($CLJS.UE,$CLJS.H(["lat-field",XS,"lon-field",XS,"lat-max",XS,"lon-min",XS,"lat-min",XS,"lon-max",XS])));var eT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,IP],null);$CLJS.Y(VM,cL($CLJS.NE,$CLJS.H(["field",$CLJS.LR])));var fT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,VM],null);$CLJS.Y(KN,cL($CLJS.ZE,$CLJS.H(["field",$CLJS.LR])));var gT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KN],null);$CLJS.Y(mN,cL($CLJS.PE,$CLJS.H(["field",$CLJS.LR])));
var hT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,mN],null);$CLJS.Y(tL,cL($CLJS.WE,$CLJS.H(["field",$CLJS.LR])));var iT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tL],null),jT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uF,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null);$CLJS.Y(zN,cL($CLJS.dF,$CLJS.H(["field",QR,"string-or-field",QR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,jT],null)])));var kT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,zN],null);
$CLJS.Y(sO,cL($CLJS.xF,$CLJS.H(["field",QR,"string-or-field",QR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,jT],null)])));var lT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,sO],null);$CLJS.Y(kN,cL($CLJS.lF,$CLJS.H(["field",QR,"string-or-field",QR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,jT],null)])));var mT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,kN],null);$CLJS.Y(aP,cL($CLJS.eF,$CLJS.H(["field",QR,"string-or-field",QR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,jT],null)])));
var nT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aP],null);$CLJS.Y(CL,cL($CLJS.dB,$CLJS.H(["field",$CLJS.LR,"n",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.qr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.Qu,$CLJS.Vw,$CLJS.TE],null)],null),"unit",BR,"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ik,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null)],null)])));var oT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CL],null);
$CLJS.Y(rP,cL($CLJS.KE,$CLJS.H(["segment-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.jD],null),mR],null)])));var pT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,rP],null);
$CLJS.Y(AM,dL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof SS?new $CLJS.Cc(function(){return SS},$CLJS.kd(kQ,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),UN,"metabase/mbql/schema.cljc",15,$CLJS.lr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lr,KM,$CLJS.V($CLJS.pj,ZN),CO,$CLJS.V($CLJS.pj,ZN),UM,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,ZN))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:UN}(),SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof TS?new $CLJS.Cc(function(){return TS},
$CLJS.kd(GO,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JO,"metabase/mbql/schema.cljc",14,$CLJS.er,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.er,KM,$CLJS.V($CLJS.pj,ZN),CO,$CLJS.V($CLJS.pj,ZN),UM,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,ZN))],null),$CLJS.Gc,
"Schema for a valid or clause.",$CLJS.n(TS)?TS.H:null])):null));return $CLJS.n(a)?a:JO}(),TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof US?new $CLJS.Cc(function(){return US},$CLJS.kd(sP,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,
"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lO,"metabase/mbql/schema.cljc",15,$CLJS.gr,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.YQ,$CLJS.V($CLJS.pj,ZN)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:lO}(),US],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==
typeof $S?new $CLJS.Cc(function(){return $S},$CLJS.kd(PO,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NN,"metabase/mbql/schema.cljc",13,$CLJS.Eq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Eq,$CLJS.mF,$CLJS.V($CLJS.pj,MM),WP,$CLJS.V($CLJS.pj,MM)],
null),$CLJS.Gc,"Schema for a valid \x3c clause.",$CLJS.n($S)?$S.H:null])):null));return $CLJS.n(a)?a:NN}(),$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof bT?new $CLJS.Cc(function(){return bT},$CLJS.kd(RQ,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,
1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MN,"metabase/mbql/schema.cljc",14,$CLJS.Gq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gq,$CLJS.mF,$CLJS.V($CLJS.pj,MM),WP,$CLJS.V($CLJS.pj,MM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(bT)?bT.H:null])):null));return $CLJS.n(a)?a:MN}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&
"undefined"!==typeof hL&&"undefined"!==typeof aT?new $CLJS.Cc(function(){return aT},$CLJS.kd(NO,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ON,"metabase/mbql/schema.cljc",13,$CLJS.Aq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Aq,$CLJS.mF,$CLJS.V($CLJS.pj,
MM),WP,$CLJS.V($CLJS.pj,MM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:ON}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof cT?new $CLJS.Cc(function(){return cT},$CLJS.kd(KQ,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],
[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),VN,"metabase/mbql/schema.cljc",14,$CLJS.Cq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Cq,$CLJS.mF,$CLJS.V($CLJS.pj,MM),WP,$CLJS.V($CLJS.pj,MM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:VN}(),cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&
"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof YS?new $CLJS.Cc(function(){return YS},$CLJS.kd(OO,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PN,"metabase/mbql/schema.cljc",13,$CLJS.Hq,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,
[$CLJS.Hq,$CLJS.mF,$CLJS.V($CLJS.pj,WQ),WP,$CLJS.V($CLJS.pj,WQ),zP,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,WQ))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n(YS)?YS.H:null])):null));return $CLJS.n(a)?a:PN}(),YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof ZS?new $CLJS.Cc(function(){return ZS},$CLJS.kd(bM,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,
$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xM,"metabase/mbql/schema.cljc",14,$CLJS.CF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.CF,$CLJS.mF,$CLJS.V($CLJS.pj,WQ),WP,$CLJS.V($CLJS.pj,WQ),zP,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,WQ))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n(ZS)?ZS.H:null])):null));
return $CLJS.n(a)?a:xM}(),ZS],null)])));
$CLJS.Y(HO,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"valid filter expression",$CLJS.xi,function(a){return $CLJS.n(bL(UR,a))?$CLJS.cI:$CLJS.n(bL(RR,a))?eR:$CLJS.n(bL(OR,a))?$CLJS.vj:$CLJS.n(bL(SR,a))?$CLJS.ur:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cI,$CLJS.YR],null),new $CLJS.P(null,2,5,$CLJS.Q,[eR,VR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,PR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ur,WR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,dL($CLJS.H([new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof SS?new $CLJS.Cc(function(){return SS},$CLJS.kd(kQ,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UN,"metabase/mbql/schema.cljc",
15,$CLJS.lr,1,724,724,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lr,KM,$CLJS.V($CLJS.pj,ZN),CO,$CLJS.V($CLJS.pj,ZN),UM,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,ZN))],null),$CLJS.Gc,"Schema for a valid and clause.",$CLJS.n(SS)?SS.H:null])):null));return $CLJS.n(a)?a:UN}(),SS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof TS?new $CLJS.Cc(function(){return TS},$CLJS.kd(GO,new $CLJS.h(null,1,[$CLJS.wj,
!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),JO,"metabase/mbql/schema.cljc",14,$CLJS.er,1,729,729,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.er,KM,$CLJS.V($CLJS.pj,ZN),CO,$CLJS.V($CLJS.pj,ZN),UM,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,ZN))],null),$CLJS.Gc,"Schema for a valid or clause.",$CLJS.n(TS)?
TS.H:null])):null));return $CLJS.n(a)?a:JO}(),TS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof US?new $CLJS.Cc(function(){return US},$CLJS.kd(sP,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),lO,"metabase/mbql/schema.cljc",15,$CLJS.gr,1,734,734,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gr,$CLJS.YQ,$CLJS.V($CLJS.pj,ZN)],null),$CLJS.Gc,"Schema for a valid not clause.",$CLJS.n(US)?US.H:null])):null));return $CLJS.n(a)?a:lO}(),US],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof YS?new $CLJS.Cc(function(){return YS},$CLJS.kd(OO,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,
$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),PN,"metabase/mbql/schema.cljc",13,$CLJS.Hq,1,795,795,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Hq,$CLJS.mF,$CLJS.V($CLJS.pj,WQ),WP,$CLJS.V($CLJS.pj,WQ),zP,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,WQ))],null),$CLJS.Gc,"Schema for a valid \x3d clause.",$CLJS.n(YS)?YS.H:null])):
null));return $CLJS.n(a)?a:PN}(),YS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof ZS?new $CLJS.Cc(function(){return ZS},$CLJS.kd(bM,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),xM,"metabase/mbql/schema.cljc",14,$CLJS.CF,1,796,796,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.CF,$CLJS.mF,$CLJS.V($CLJS.pj,WQ),WP,$CLJS.V($CLJS.pj,WQ),zP,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,WQ))],null),$CLJS.Gc,"Schema for a valid !\x3d clause.",$CLJS.n(ZS)?ZS.H:null])):null));return $CLJS.n(a)?a:xM}(),ZS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof $S?new $CLJS.Cc(function(){return $S},
$CLJS.kd(PO,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NN,"metabase/mbql/schema.cljc",13,$CLJS.Eq,1,798,798,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Eq,$CLJS.mF,$CLJS.V($CLJS.pj,MM),WP,$CLJS.V($CLJS.pj,MM)],null),$CLJS.Gc,"Schema for a valid \x3c clause.",
$CLJS.n($S)?$S.H:null])):null));return $CLJS.n(a)?a:NN}(),$S],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof aT?new $CLJS.Cc(function(){return aT},$CLJS.kd(NO,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),ON,"metabase/mbql/schema.cljc",13,$CLJS.Aq,1,799,799,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Aq,$CLJS.mF,$CLJS.V($CLJS.pj,MM),WP,$CLJS.V($CLJS.pj,MM)],null),$CLJS.Gc,"Schema for a valid \x3e clause.",$CLJS.n(aT)?aT.H:null])):null));return $CLJS.n(a)?a:ON}(),aT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof bT?new $CLJS.Cc(function(){return bT},$CLJS.kd(RQ,new $CLJS.h(null,
1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MN,"metabase/mbql/schema.cljc",14,$CLJS.Gq,1,800,800,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Gq,$CLJS.mF,$CLJS.V($CLJS.pj,MM),WP,$CLJS.V($CLJS.pj,MM)],null),$CLJS.Gc,"Schema for a valid \x3c\x3d clause.",$CLJS.n(bT)?bT.H:null])):
null));return $CLJS.n(a)?a:MN}(),bT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof cT?new $CLJS.Cc(function(){return cT},$CLJS.kd(KQ,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),VN,"metabase/mbql/schema.cljc",14,$CLJS.Cq,1,801,801,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Cq,$CLJS.mF,$CLJS.V($CLJS.pj,MM),WP,$CLJS.V($CLJS.pj,MM)],null),$CLJS.Gc,"Schema for a valid \x3e\x3d clause.",$CLJS.n(cT)?cT.H:null])):null));return $CLJS.n(a)?a:VN}(),cT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof dT?new $CLJS.Cc(function(){return dT},$CLJS.kd(Qka,new $CLJS.h(null,
1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dM,"metabase/mbql/schema.cljc",19,$CLJS.OE,1,804,804,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.OE,$CLJS.mF,$CLJS.V($CLJS.pj,MM),$CLJS.si,$CLJS.V($CLJS.pj,MM),$CLJS.Bj,$CLJS.V($CLJS.pj,MM)],null),$CLJS.Gc,"Schema for a valid between clause.",
$CLJS.n(dT)?dT.H:null])):null));return $CLJS.n(a)?a:dM}(),dT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof kT?new $CLJS.Cc(function(){return kT},$CLJS.kd(Ska,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),aQ,"metabase/mbql/schema.cljc",23,$CLJS.dF,1,829,829,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.dF,$CLJS.mF,$CLJS.V($CLJS.pj,VP),wM,$CLJS.V($CLJS.pj,VP),$CLJS.Ni,$CLJS.V($CLJS.pj,$CLJS.V(gQ,qP))],null),$CLJS.Gc,"Schema for a valid starts-with clause.",$CLJS.n(kT)?kT.H:null])):null));return $CLJS.n(a)?a:aQ}(),kT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof lT?new $CLJS.Cc(function(){return lT},
$CLJS.kd(Tja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),BM,"metabase/mbql/schema.cljc",21,$CLJS.xF,1,830,830,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.xF,$CLJS.mF,$CLJS.V($CLJS.pj,VP),wM,$CLJS.V($CLJS.pj,VP),$CLJS.Ni,$CLJS.V($CLJS.pj,$CLJS.V(gQ,qP))],null),
$CLJS.Gc,"Schema for a valid ends-with clause.",$CLJS.n(lT)?lT.H:null])):null));return $CLJS.n(a)?a:BM}(),lT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof mT?new $CLJS.Cc(function(){return mT},$CLJS.kd(fka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,
1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),NM,"metabase/mbql/schema.cljc",20,$CLJS.lF,1,831,831,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.lF,$CLJS.mF,$CLJS.V($CLJS.pj,VP),wM,$CLJS.V($CLJS.pj,VP),$CLJS.Ni,$CLJS.V($CLJS.pj,$CLJS.V(gQ,qP))],null),$CLJS.Gc,"Schema for a valid contains clause.",$CLJS.n(mT)?mT.H:null])):null));return $CLJS.n(a)?a:NM}(),mT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==
typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof nT?new $CLJS.Cc(function(){return nT},$CLJS.kd(Jla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rL,"metabase/mbql/schema.cljc",36,$CLJS.eF,1,834,834,!0,new $CLJS.P(null,
7,5,$CLJS.Q,[$CLJS.eF,$CLJS.mF,$CLJS.V($CLJS.pj,VP),wM,$CLJS.V($CLJS.pj,VP),$CLJS.Ni,$CLJS.V($CLJS.pj,$CLJS.V(gQ,qP))],null),$CLJS.Gc,"Schema for a valid does-not-contain clause.",$CLJS.n(nT)?nT.H:null])):null));return $CLJS.n(a)?a:rL}(),nT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof eT?new $CLJS.Cc(function(){return eT},$CLJS.kd(sla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),
$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),ZP,"metabase/mbql/schema.cljc",26,$CLJS.UE,1,807,807,!0,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.UE,Bja,$CLJS.V($CLJS.pj,MM),Ola,$CLJS.V($CLJS.pj,MM),zla,$CLJS.V($CLJS.pj,MM),Eja,$CLJS.V($CLJS.pj,MM),tla,$CLJS.V($CLJS.pj,MM),Aja,$CLJS.V($CLJS.pj,
MM)],null),$CLJS.Gc,"Schema for a valid inside clause.",$CLJS.n(eT)?eT.H:null])):null));return $CLJS.n(a)?a:ZP}(),eT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof hT?new $CLJS.Cc(function(){return hT},$CLJS.kd(gka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,
1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),uO,"metabase/mbql/schema.cljc",28,$CLJS.PE,1,821,821,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.PE,$CLJS.mF,$CLJS.V($CLJS.pj,FM)],null),$CLJS.Gc,"Schema for a valid is-empty clause.",$CLJS.n(hT)?hT.H:null])):null));return $CLJS.n(a)?a:uO}(),hT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&
"undefined"!==typeof iT?new $CLJS.Cc(function(){return iT},$CLJS.kd(dla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),hP,"metabase/mbql/schema.cljc",29,$CLJS.WE,1,822,822,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WE,$CLJS.mF,$CLJS.V($CLJS.pj,FM)],null),
$CLJS.Gc,"Schema for a valid not-empty clause.",$CLJS.n(iT)?iT.H:null])):null));return $CLJS.n(a)?a:hP}(),iT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof fT?new $CLJS.Cc(function(){return fT},$CLJS.kd(Ela,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,
1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TM,"metabase/mbql/schema.cljc",27,$CLJS.NE,1,816,816,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NE,$CLJS.mF,$CLJS.V($CLJS.pj,FM)],null),$CLJS.Gc,"Schema for a valid is-null clause.",$CLJS.n(fT)?fT.H:null])):null));return $CLJS.n(a)?a:TM}(),fT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&
"undefined"!==typeof gT?new $CLJS.Cc(function(){return gT},$CLJS.kd(ska,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iM,"metabase/mbql/schema.cljc",28,$CLJS.ZE,1,817,817,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZE,$CLJS.mF,$CLJS.V($CLJS.pj,FM)],null),
$CLJS.Gc,"Schema for a valid not-null clause.",$CLJS.n(gT)?gT.H:null])):null));return $CLJS.n(a)?a:iM}(),gT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof oT?new $CLJS.Cc(function(){return oT},$CLJS.kd(sja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,
1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GP,"metabase/mbql/schema.cljc",33,$CLJS.dB,1,856,856,!0,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.dB,$CLJS.mF,$CLJS.V($CLJS.pj,FM),$CLJS.Kk,$CLJS.V($CLJS.pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.qr,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.Qu,$CLJS.Vw,$CLJS.TE],null)],null)),$CLJS.Bk,$CLJS.V($CLJS.pj,TN),$CLJS.Ni,$CLJS.V($CLJS.pj,$CLJS.V(gQ,Vka))],null),$CLJS.Gc,"Schema for a valid time-interval clause.",
$CLJS.n(oT)?oT.H:null])):null));return $CLJS.n(a)?a:GP}(),oT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof pT?new $CLJS.Cc(function(){return pT},$CLJS.kd(Aka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),IQ,"metabase/mbql/schema.cljc",27,$CLJS.KE,1,872,872,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KE,Ija,$CLJS.V($CLJS.pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,jla,VL],null))],null),$CLJS.Gc,"Schema for a valid segment clause.",$CLJS.n(pT)?pT.H:null])):null));return $CLJS.n(a)?a:IQ}(),pT],null)]))],null)],null));
$CLJS.Y(tP,cL($CLJS.VE,$CLJS.H(["clauses",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.h(null,1,[$CLJS.us,":case subclause"],null),$CLJS.RS,bS],null)],null)],null),"options",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.us,":case options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Br,!0],null),bS],null)],null)],null)])));
var qT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tP],null);
$CLJS.Y($P,dL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof pS?new $CLJS.Cc(function(){return pS},$CLJS.kd(RO,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),FN,"metabase/mbql/schema.cljc",51,$CLJS.Jq,1,612,612,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Jq,$CLJS.AD,$CLJS.V($CLJS.pj,VO),rQ,$CLJS.V($CLJS.pj,VO),JM,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,VO))],null),$CLJS.Gc,"Schema for a valid + clause.",$CLJS.n(pS)?pS.H:null])):null));return $CLJS.n(a)?a:FN}(),pS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof qS?new $CLJS.Cc(function(){return qS},
$CLJS.kd(Mka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),GN,"metabase/mbql/schema.cljc",51,$CLJS.$r,1,615,615,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.$r,$CLJS.AD,$CLJS.V($CLJS.pj,QP),
rQ,$CLJS.V($CLJS.pj,VO),JM,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,VO))],null),$CLJS.Gc,"Schema for a valid - clause.",$CLJS.n(qS)?qS.H:null])):null));return $CLJS.n(a)?a:GN}(),qS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL?new $CLJS.Cc(function(){return rS},$CLJS.kd(Lka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,iO,$CLJS.Bi,$CLJS.Si,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],
[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.bz,"metabase/mbql/schema.cljc",$CLJS.GF,1,618,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.GF,$CLJS.AD,$CLJS.V($CLJS.pj,QP),rQ,$CLJS.V($CLJS.pj,QP),JM,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,QP))],null),$CLJS.Gc,"Schema for a valid / clause.",$CLJS.n(rS)?rS.H:null])):null));return $CLJS.n(a)?
a:$CLJS.bz}(),rS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof sS?new $CLJS.Cc(function(){return sS},$CLJS.kd(Nka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),EN,"metabase/mbql/schema.cljc",51,$CLJS.Kq,1,620,620,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.Kq,$CLJS.AD,$CLJS.V($CLJS.pj,QP),rQ,$CLJS.V($CLJS.pj,QP),JM,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,QP))],null),$CLJS.Gc,"Schema for a valid * clause.",$CLJS.n(sS)?sS.H:null])):null));return $CLJS.n(a)?a:EN}(),sS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof dS?new $CLJS.Cc(function(){return dS},
$CLJS.kd(DP,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SP,"metabase/mbql/schema.cljc",58,$CLJS.pF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.pF,AQ,$CLJS.V($CLJS.pj,wL),zQ,$CLJS.V($CLJS.pj,
wL),JM,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,wL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n(dS)?dS.H:null])):null));return $CLJS.n(a)?a:SP}(),dS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof fS?new $CLJS.Cc(function(){return fS},$CLJS.kd(cla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,
$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),kP,"metabase/mbql/schema.cljc",56,$CLJS.Pw,1,585,585,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pw,$CLJS.lx,$CLJS.V($CLJS.pj,VP)],null),$CLJS.Gc,"Schema for a valid length clause.",$CLJS.n(fS)?fS.H:null])):null));return $CLJS.n(a)?a:kP}(),fS],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof tS?new $CLJS.Cc(function(){return tS},$CLJS.kd(Uja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),fO,"metabase/mbql/schema.cljc",55,$CLJS.kF,1,622,622,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.kF,$CLJS.AD,$CLJS.V($CLJS.pj,QP)],null),$CLJS.Gc,"Schema for a valid floor clause.",$CLJS.n(tS)?tS.H:null])):null));return $CLJS.n(a)?a:fO}(),tS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof uS?new $CLJS.Cc(function(){return uS},$CLJS.kd(Oja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),
$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),UL,"metabase/mbql/schema.cljc",54,$CLJS.HE,1,625,625,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HE,$CLJS.AD,$CLJS.V($CLJS.pj,QP)],null),$CLJS.Gc,"Schema for a valid ceil clause.",$CLJS.n(uS)?
uS.H:null])):null));return $CLJS.n(a)?a:UL}(),uS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof vS?new $CLJS.Cc(function(){return vS},$CLJS.kd(cka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,
1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WN,"metabase/mbql/schema.cljc",55,$CLJS.rF,1,628,628,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.rF,$CLJS.AD,$CLJS.V($CLJS.pj,QP)],null),$CLJS.Gc,"Schema for a valid round clause.",$CLJS.n(vS)?vS.H:null])):null));return $CLJS.n(a)?a:WN}(),vS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&
"undefined"!==typeof wS?new $CLJS.Cc(function(){return wS},$CLJS.kd(Kla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),yO,"metabase/mbql/schema.cljc",53,$CLJS.ME,1,631,631,new $CLJS.P(null,
3,5,$CLJS.Q,[$CLJS.ME,$CLJS.AD,$CLJS.V($CLJS.pj,QP)],null),$CLJS.Gc,"Schema for a valid abs clause.",$CLJS.n(wS)?wS.H:null])):null));return $CLJS.n(a)?a:yO}(),wS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof xS?new $CLJS.Cc(function(){return xS},$CLJS.kd(xla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,
eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[OQ,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),jQ,"metabase/mbql/schema.cljc",69,$CLJS.JE,1,634,634,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.JE,$CLJS.AD,$CLJS.V($CLJS.pj,QP),rQ,$CLJS.V($CLJS.pj,QP)],null),$CLJS.Gc,"Schema for a valid power clause.",$CLJS.n(xS)?xS.H:null])):null));return $CLJS.n(a)?a:jQ}(),xS],
null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof yS?new $CLJS.Cc(function(){return yS},$CLJS.kd(vka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[OQ,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),AN,"metabase/mbql/schema.cljc",68,$CLJS.$E,1,637,637,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.$E,$CLJS.AD,$CLJS.V($CLJS.pj,QP)],null),$CLJS.Gc,"Schema for a valid sqrt clause.",$CLJS.n(yS)?yS.H:null])):null));return $CLJS.n(a)?a:AN}(),yS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof zS?new $CLJS.Cc(function(){return zS},$CLJS.kd(yla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),
$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[OQ,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),YN,"metabase/mbql/schema.cljc",67,$CLJS.DE,1,640,640,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DE,$CLJS.AD,$CLJS.V($CLJS.pj,QP)],null),$CLJS.Gc,"Schema for a valid exp clause.",$CLJS.n(zS)?
zS.H:null])):null));return $CLJS.n(a)?a:YN}(),zS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof AS?new $CLJS.Cc(function(){return AS},$CLJS.kd(Hja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[OQ,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,
1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),TQ,"metabase/mbql/schema.cljc",67,$CLJS.IF,1,643,643,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IF,$CLJS.AD,$CLJS.V($CLJS.pj,QP)],null),$CLJS.Gc,"Schema for a valid log clause.",$CLJS.n(AS)?AS.H:null])):null));return $CLJS.n(a)?a:TQ}(),AS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&
"undefined"!==typeof qT?new $CLJS.Cc(function(){return qT},$CLJS.kd(HL,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.GE,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pO,"metabase/mbql/schema.cljc",61,$CLJS.VE,1,909,909,new $CLJS.P(null,
5,5,$CLJS.Q,[$CLJS.VE,$CLJS.bw,$CLJS.V($CLJS.pj,ZM),$CLJS.Ni,$CLJS.V($CLJS.pj,$CLJS.V(gQ,IO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:pO}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof BS?new $CLJS.Cc(function(){return BS},$CLJS.kd(Kja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,
$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.YA,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),zO,"metabase/mbql/schema.cljc",65,$CLJS.YA,1,656,656,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.YA,Xja,$CLJS.V($CLJS.pj,dR),Yja,$CLJS.V($CLJS.pj,dR),$CLJS.Bk,$CLJS.V($CLJS.pj,Dka)],null),$CLJS.Gc,"Schema for a valid datetime-diff clause.",
$CLJS.n(BS)?BS.H:null])):null));return $CLJS.n(a)?a:zO}(),BS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof CS?new $CLJS.Cc(function(){return CS},$CLJS.kd(gla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(OP,
new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),SO,"metabase/mbql/schema.cljc",71,$CLJS.cB,1,661,661,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.cB,$CLJS.cI,$CLJS.V($CLJS.pj,dR),$CLJS.Bk,$CLJS.V($CLJS.pj,Dla),$CLJS.hi,$CLJS.V($CLJS.pj,$CLJS.V(gQ,lR))],null),$CLJS.Gc,"Schema for a valid temporal-extract clause.",$CLJS.n(CS)?CS.H:null])):null));return $CLJS.n(a)?a:SO}(),CS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof DS?new $CLJS.Cc(function(){return DS},$CLJS.kd(oka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),qM,"metabase/mbql/schema.cljc",71,$CLJS.YH,1,667,667,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YH,$CLJS.BO,$CLJS.V($CLJS.pj,dR)],null),$CLJS.Gc,"Schema for a valid get-year clause.",$CLJS.n(DS)?DS.H:null])):null));return $CLJS.n(a)?a:qM}(),DS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof ES?new $CLJS.Cc(function(){return ES},$CLJS.kd(dka,new $CLJS.h(null,1,[$CLJS.wj,!0],
null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),QO,"metabase/mbql/schema.cljc",74,$CLJS.nI,1,670,670,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nI,$CLJS.BO,$CLJS.V($CLJS.pj,dR)],null),$CLJS.Gc,"Schema for a valid get-quarter clause.",
$CLJS.n(ES)?ES.H:null])):null));return $CLJS.n(a)?a:QO}(),ES],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof FS?new $CLJS.Cc(function(){return FS},$CLJS.kd(Kka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),
$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mP,"metabase/mbql/schema.cljc",72,$CLJS.RH,1,673,673,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RH,$CLJS.BO,$CLJS.V($CLJS.pj,dR)],null),$CLJS.Gc,"Schema for a valid get-month clause.",$CLJS.n(FS)?FS.H:null])):null));return $CLJS.n(a)?a:mP}(),FS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==
typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof GS?new $CLJS.Cc(function(){return GS},$CLJS.kd(Cla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),lP,"metabase/mbql/schema.cljc",
71,$CLJS.aB,1,676,676,!0,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.aB,$CLJS.BO,$CLJS.V($CLJS.pj,dR),$CLJS.hi,$CLJS.V($CLJS.pj,$CLJS.V(gQ,lR))],null),$CLJS.Gc,"Schema for a valid get-week clause.",$CLJS.n(GS)?GS.H:null])):null));return $CLJS.n(a)?a:lP}(),GS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof HS?new $CLJS.Cc(function(){return HS},$CLJS.kd(yja,new $CLJS.h(null,1,[$CLJS.wj,
!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),eP,"metabase/mbql/schema.cljc",70,$CLJS.gI,1,680,680,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gI,$CLJS.BO,$CLJS.V($CLJS.pj,dR)],null),$CLJS.Gc,"Schema for a valid get-day clause.",
$CLJS.n(HS)?HS.H:null])):null));return $CLJS.n(a)?a:eP}(),HS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof IS?new $CLJS.Cc(function(){return IS},$CLJS.kd(Eka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),
$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),WO,"metabase/mbql/schema.cljc",78,$CLJS.jI,1,683,683,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jI,$CLJS.BO,$CLJS.V($CLJS.pj,dR)],null),$CLJS.Gc,"Schema for a valid get-day-of-week clause.",$CLJS.n(IS)?IS.H:null])):null));return $CLJS.n(a)?a:WO}(),IS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==
typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof JS?new $CLJS.Cc(function(){return JS},$CLJS.kd(Cka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),xL,"metabase/mbql/schema.cljc",
71,$CLJS.lI,1,686,686,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lI,$CLJS.cI,$CLJS.V($CLJS.pj,dR)],null),$CLJS.Gc,"Schema for a valid get-hour clause.",$CLJS.n(JS)?JS.H:null])):null));return $CLJS.n(a)?a:xL}(),JS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof KS?new $CLJS.Cc(function(){return KS},$CLJS.kd(kla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,
$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),XQ,"metabase/mbql/schema.cljc",73,$CLJS.iI,1,689,689,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iI,$CLJS.cI,$CLJS.V($CLJS.pj,dR)],null),$CLJS.Gc,"Schema for a valid get-minute clause.",$CLJS.n(KS)?KS.H:null])):null));
return $CLJS.n(a)?a:XQ}(),KS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof LS?new $CLJS.Cc(function(){return LS},$CLJS.kd(zka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.cB,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,
[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rO,"metabase/mbql/schema.cljc",73,$CLJS.XH,1,692,692,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XH,$CLJS.cI,$CLJS.V($CLJS.pj,dR)],null),$CLJS.Gc,"Schema for a valid get-second clause.",$CLJS.n(LS)?LS.H:null])):null));return $CLJS.n(a)?a:rO}(),LS],null)])));
$CLJS.Y(ML,dL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof eS?new $CLJS.Cc(function(){return eS},$CLJS.kd(ala,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),uP,"metabase/mbql/schema.cljc",59,$CLJS.EE,1,582,582,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.EE,$CLJS.lx,$CLJS.V($CLJS.pj,VP),$CLJS.vu,$CLJS.V($CLJS.pj,$ja),$CLJS.Pw,$CLJS.V($CLJS.pj,$CLJS.V(gQ,QP))],null),$CLJS.Gc,"Schema for a valid substring clause.",$CLJS.n(eS)?eS.H:null])):null));return $CLJS.n(a)?a:uP}(),eS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof gS?new $CLJS.Cc(function(){return gS},
$CLJS.kd(Ala,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),RP,"metabase/mbql/schema.cljc",54,$CLJS.BF,1,588,588,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.BF,$CLJS.lx,$CLJS.V($CLJS.pj,VP)],
null),$CLJS.Gc,"Schema for a valid trim clause.",$CLJS.n(gS)?gS.H:null])):null));return $CLJS.n(a)?a:RP}(),gS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof iS?new $CLJS.Cc(function(){return iS},$CLJS.kd(Fka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,
1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),AP,"metabase/mbql/schema.cljc",55,$CLJS.DF,1,594,594,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DF,$CLJS.lx,$CLJS.V($CLJS.pj,VP)],null),$CLJS.Gc,"Schema for a valid ltrim clause.",$CLJS.n(iS)?iS.H:null])):null));return $CLJS.n(a)?a:AP}(),iS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&
"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof hS?new $CLJS.Cc(function(){return hS},$CLJS.kd(bla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),DO,"metabase/mbql/schema.cljc",
55,$CLJS.KF,1,591,591,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.KF,$CLJS.lx,$CLJS.V($CLJS.pj,VP)],null),$CLJS.Gc,"Schema for a valid rtrim clause.",$CLJS.n(hS)?hS.H:null])):null));return $CLJS.n(a)?a:DO}(),hS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof lS?new $CLJS.Cc(function(){return lS},$CLJS.kd(tja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,
$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),CQ,"metabase/mbql/schema.cljc",57,$CLJS.JF,1,603,603,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.JF,$CLJS.lx,$CLJS.V($CLJS.pj,VP),Fja,$CLJS.V($CLJS.pj,$CLJS.vj),$CLJS.iz,$CLJS.V($CLJS.pj,$CLJS.vj)],null),$CLJS.Gc,"Schema for a valid replace clause.",
$CLJS.n(lS)?lS.H:null])):null));return $CLJS.n(a)?a:CQ}(),lS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof kS?new $CLJS.Cc(function(){return kS},$CLJS.kd(ela,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,
new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),gR,"metabase/mbql/schema.cljc",55,$CLJS.qs,1,600,600,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qs,$CLJS.lx,$CLJS.V($CLJS.pj,VP)],null),$CLJS.Gc,"Schema for a valid lower clause.",$CLJS.n(kS)?kS.H:null])):null));return $CLJS.n(a)?a:gR}(),kS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==
typeof hL&&"undefined"!==typeof jS?new $CLJS.Cc(function(){return jS},$CLJS.kd(Bla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),dQ,"metabase/mbql/schema.cljc",55,$CLJS.ks,1,597,597,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ks,$CLJS.lx,$CLJS.V($CLJS.pj,VP)],null),$CLJS.Gc,"Schema for a valid upper clause.",$CLJS.n(jS)?jS.H:null])):null));return $CLJS.n(a)?a:dQ}(),jS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof nS?new $CLJS.Cc(function(){return nS},$CLJS.kd(Uka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,
$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),rN,"metabase/mbql/schema.cljc",56,$CLJS.LE,1,606,606,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.LE,AQ,$CLJS.V($CLJS.pj,VP),zQ,$CLJS.V($CLJS.pj,VP),JM,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,VP))],null),$CLJS.Gc,"Schema for a valid concat clause.",
$CLJS.n(nS)?nS.H:null])):null));return $CLJS.n(a)?a:rN}(),nS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof oS?new $CLJS.Cc(function(){return oS},$CLJS.kd($ka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.ZO,"null",$CLJS.Gk,"null"],
null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oM,"metabase/mbql/schema.cljc",74,$CLJS.oF,1,609,609,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.oF,$CLJS.lx,$CLJS.V($CLJS.pj,VP),$CLJS.hz,$CLJS.V($CLJS.pj,$CLJS.vj)],null),$CLJS.Gc,"Schema for a valid regex-match-first clause.",$CLJS.n(oS)?oS.H:null])):null));return $CLJS.n(a)?a:oM}(),oS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==
typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof dS?new $CLJS.Cc(function(){return dS},$CLJS.kd(DP,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.ZO,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
SP,"metabase/mbql/schema.cljc",58,$CLJS.pF,1,579,579,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.pF,AQ,$CLJS.V($CLJS.pj,wL),zQ,$CLJS.V($CLJS.pj,wL),JM,$CLJS.V($CLJS.pj,$CLJS.V($CLJS.vi,wL))],null),$CLJS.Gc,"Schema for a valid coalesce clause.",$CLJS.n(dS)?dS.H:null])):null));return $CLJS.n(a)?a:SP}(),dS],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof qT?new $CLJS.Cc(function(){return qT},
$CLJS.kd(HL,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.GE,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pO,"metabase/mbql/schema.cljc",61,$CLJS.VE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.VE,$CLJS.bw,$CLJS.V($CLJS.pj,ZM),
$CLJS.Ni,$CLJS.V($CLJS.pj,$CLJS.V(gQ,IO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:pO}(),qT],null)])));
$CLJS.rT=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,":field or :expression reference or expression",$CLJS.xi,function(a){return $CLJS.n(bL(RR,a))?eR:$CLJS.n(bL(OR,a))?$CLJS.vj:$CLJS.n(bL(SR,a))?$CLJS.ur:$CLJS.n(bL(UR,a))?$CLJS.cI:$CLJS.n(bL($CLJS.VE,a))?$CLJS.VE:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[eR,VR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vj,PR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ur,WR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cI,$CLJS.YR],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.VE,qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,$CLJS.LR],null)],null);$CLJS.Y(LQ,cL($CLJS.Rw,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,$CLJS.LR],null)])));var sT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LQ],null);$CLJS.Y(AO,cL($CLJS.nF,$CLJS.H(["field",new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Br,$CLJS.LR],null)])));var tT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,AO],null);$CLJS.Y(KL,cL($CLJS.gF,$CLJS.H(["field-or-expression",$CLJS.rT])));
var uT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KL],null);$CLJS.Y(DQ,cL($CLJS.HF,$CLJS.H(["field-or-expression",$CLJS.rT])));var vT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DQ],null);$CLJS.Y(jN,cL($CLJS.RE,$CLJS.H(["field-or-expression",$CLJS.rT])));var wT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jN],null);$CLJS.Y(TP,cL($CLJS.yF,$CLJS.H(["field-or-expression",$CLJS.rT])));var xT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,TP],null);$CLJS.Y(eN,cL($CLJS.si,$CLJS.H(["field-or-expression",$CLJS.rT])));
var yT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,eN],null);$CLJS.Y(RM,cL($CLJS.Bj,$CLJS.H(["field-or-expression",$CLJS.rT])));var zT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,RM],null);$CLJS.Y(LM,cL($CLJS.FF,$CLJS.H(["field-or-expression",$CLJS.rT,"pred",$CLJS.RS])));var AT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,LM],null);$CLJS.Y(bN,cL($CLJS.vF,$CLJS.H(["pred",$CLJS.RS])));var BT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bN],null);$CLJS.Y(KP,cL($CLJS.tF,$CLJS.H(["pred",$CLJS.RS])));
var CT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,KP],null);$CLJS.Y(hM,cL($CLJS.AF,$CLJS.H(["field-or-expression",$CLJS.rT])));var DT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hM],null);$CLJS.Y(jL,cL($CLJS.SE,$CLJS.H(["field-or-expression",$CLJS.rT])));var ET=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jL],null);$CLJS.Y(nN,cL($CLJS.zF,$CLJS.H(["field-or-expression",$CLJS.rT])));var FT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nN],null);$CLJS.Y(aO,cL($CLJS.YE,$CLJS.H(["field-or-expression",$CLJS.rT,"percentile",$R])));
var GT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,aO],null);$CLJS.Y(NP,cL($CLJS.hF,$CLJS.H(["metric-id",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.hD],null),mR],null)])));var HT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,NP],null);
$CLJS.Y(JP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"unnamed aggregation clause or numeric expression",$CLJS.xi,function(a){return $CLJS.n(bL(RR,a))?dO:$CLJS.ms}],null),new $CLJS.P(null,2,5,$CLJS.Q,[dO,VR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ms,dL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof uT?new $CLJS.Cc(function(){return uT},$CLJS.kd(Zka,
new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.GE,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),mO,"metabase/mbql/schema.cljc",60,$CLJS.gF,1,957,957,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gF,eQ,$CLJS.V($CLJS.pj,XP)],null),$CLJS.Gc,"Schema for a valid avg clause.",
$CLJS.n(uT)?uT.H:null])):null));return $CLJS.n(a)?a:mO}(),uT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof vT?new $CLJS.Cc(function(){return vT},$CLJS.kd(kka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.GE,"null"],null),null),$CLJS.kd(OP,
new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qQ,"metabase/mbql/schema.cljc",64,$CLJS.HF,1,958,958,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HF,eQ,$CLJS.V($CLJS.pj,XP)],null),$CLJS.Gc,"Schema for a valid cum-sum clause.",$CLJS.n(vT)?vT.H:null])):null));return $CLJS.n(a)?a:qQ}(),vT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==
typeof hL&&"undefined"!==typeof wT?new $CLJS.Cc(function(){return wT},$CLJS.kd(bka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.GE,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qO,"metabase/mbql/schema.cljc",65,$CLJS.RE,1,959,959,
new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RE,eQ,$CLJS.V($CLJS.pj,XP)],null),$CLJS.Gc,"Schema for a valid distinct clause.",$CLJS.n(wT)?wT.H:null])):null));return $CLJS.n(a)?a:qO}(),wT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof DT?new $CLJS.Cc(function(){return DT},$CLJS.kd(ula,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,
$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.wF,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EL,"metabase/mbql/schema.cljc",76,$CLJS.AF,1,973,973,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.AF,eQ,$CLJS.V($CLJS.pj,XP)],null),$CLJS.Gc,"Schema for a valid stddev clause.",$CLJS.n(DT)?DT.H:null])):null));return $CLJS.n(a)?a:EL}(),DT],null),new $CLJS.P(null,
2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof xT?new $CLJS.Cc(function(){return xT},$CLJS.kd(Pka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.GE,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),$L,"metabase/mbql/schema.cljc",60,$CLJS.yF,1,960,960,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.yF,eQ,$CLJS.V($CLJS.pj,XP)],null),$CLJS.Gc,"Schema for a valid sum clause.",$CLJS.n(xT)?xT.H:null])):null));return $CLJS.n(a)?a:$L}(),xT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof yT?new $CLJS.Cc(function(){return yT},$CLJS.kd(Cja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,
$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.GE,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Nr,"metabase/mbql/schema.cljc",60,$CLJS.si,1,961,961,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.si,eQ,$CLJS.V($CLJS.pj,XP)],null),$CLJS.Gc,"Schema for a valid min clause.",$CLJS.n(yT)?yT.H:
null])):null));return $CLJS.n(a)?a:$CLJS.Nr}(),yT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof zT?new $CLJS.Cc(function(){return zT},$CLJS.kd(xja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.GE,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,
1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.Rr,"metabase/mbql/schema.cljc",60,$CLJS.Bj,1,962,962,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Bj,eQ,$CLJS.V($CLJS.pj,XP)],null),$CLJS.Gc,"Schema for a valid max clause.",$CLJS.n(zT)?zT.H:null])):null));return $CLJS.n(a)?a:$CLJS.Rr}(),zT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&
"undefined"!==typeof HT?new $CLJS.Cc(function(){return HT},$CLJS.kd(Zja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),qN,"metabase/mbql/schema.cljc",18,$CLJS.hF,1,994,994,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.hF,lka,$CLJS.V($CLJS.pj,new $CLJS.P(null,3,
5,$CLJS.Q,[$CLJS.er,pka,VL],null))],null),$CLJS.Gc,"Schema for a valid metric clause.",$CLJS.n(HT)?HT.H:null])):null));return $CLJS.n(a)?a:qN}(),HT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof CT?new $CLJS.Cc(function(){return CT},$CLJS.kd(xka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,
$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.GE,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),iN,"metabase/mbql/schema.cljc",62,$CLJS.tF,1,970,970,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tF,$CLJS.vn,$CLJS.V($CLJS.pj,ZN)],null),$CLJS.Gc,"Schema for a valid share clause.",$CLJS.n(CT)?CT.H:null])):null));return $CLJS.n(a)?a:iN}(),CT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=
iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof BT?new $CLJS.Cc(function(){return BT},$CLJS.kd(Dja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.GE,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),WL,"metabase/mbql/schema.cljc",68,$CLJS.vF,1,967,967,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.vF,$CLJS.vn,$CLJS.V($CLJS.pj,ZN)],null),$CLJS.Gc,"Schema for a valid count-where clause.",$CLJS.n(BT)?BT.H:null])):null));return $CLJS.n(a)?a:WL}(),BT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof AT?new $CLJS.Cc(function(){return AT},$CLJS.kd(Ila,new $CLJS.h(null,1,[$CLJS.wj,!0],
null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.GE,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nO,"metabase/mbql/schema.cljc",66,$CLJS.FF,1,964,964,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.FF,eQ,$CLJS.V($CLJS.pj,XP),$CLJS.vn,$CLJS.V($CLJS.pj,ZN)],null),$CLJS.Gc,"Schema for a valid sum-where clause.",
$CLJS.n(AT)?AT.H:null])):null));return $CLJS.n(a)?a:nO}(),AT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof qT?new $CLJS.Cc(function(){return qT},$CLJS.kd(HL,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.GE,"null"],null),null),$CLJS.kd(OP,
new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),pO,"metabase/mbql/schema.cljc",61,$CLJS.VE,1,909,909,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.VE,$CLJS.bw,$CLJS.V($CLJS.pj,ZM),$CLJS.Ni,$CLJS.V($CLJS.pj,$CLJS.V(gQ,IO))],null),$CLJS.Gc,"Schema for a valid case clause.",$CLJS.n(qT)?qT.H:null])):null));return $CLJS.n(a)?a:pO}(),qT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&
"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof FT?new $CLJS.Cc(function(){return FT},$CLJS.kd(nla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.iF,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),oQ,"metabase/mbql/schema.cljc",
68,$CLJS.zF,1,979,979,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zF,eQ,$CLJS.V($CLJS.pj,XP)],null),$CLJS.Gc,"Schema for a valid median clause.",$CLJS.n(FT)?FT.H:null])):null));return $CLJS.n(a)?a:oQ}(),FT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof GT?new $CLJS.Cc(function(){return GT},$CLJS.kd(Jka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,
iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.iF,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),MP,"metabase/mbql/schema.cljc",72,$CLJS.YE,1,982,982,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.YE,eQ,$CLJS.V($CLJS.pj,XP),$CLJS.YE,$CLJS.V($CLJS.pj,QP)],null),$CLJS.Gc,"Schema for a valid percentile clause.",$CLJS.n(GT)?GT.H:null])):
null));return $CLJS.n(a)?a:MP}(),GT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof ET?new $CLJS.Cc(function(){return ET},$CLJS.kd(Nja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],
null)),zL,"metabase/mbql/schema.cljc",77,$CLJS.SE,1,976,976,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SE,eQ,$CLJS.V($CLJS.pj,XP)],null),$CLJS.Gc,"Schema for a valid var clause.",$CLJS.n(ET)?ET.H:null])):null));return $CLJS.n(a)?a:zL}(),ET],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof tT?new $CLJS.Cc(function(){return tT},$CLJS.kd(zja,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,
$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.GE,"null"],null),null),$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$N,"metabase/mbql/schema.cljc",74,$CLJS.nF,1,947,947,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.nF,$CLJS.mF,$CLJS.V($CLJS.pj,$CLJS.V(gQ,FM))],null),$CLJS.Gc,"Schema for a valid cum-count clause.",
$CLJS.n(tT)?tT.H:null])):null));return $CLJS.n(a)?a:$N}(),tT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof sT?new $CLJS.Cc(function(){return sT},$CLJS.kd(aka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([gO,$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,iQ,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.GE,"null"],null),null),
$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),$CLJS.gi,"metabase/mbql/schema.cljc",70,$CLJS.Rw,1,946,946,!0,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rw,$CLJS.mF,$CLJS.V($CLJS.pj,$CLJS.V(gQ,FM))],null),$CLJS.Gc,"Schema for a valid count clause.",$CLJS.n(sT)?sT.H:null])):null));return $CLJS.n(a)?a:$CLJS.gi}(),sT],null)]))],null)],null));
$CLJS.Y(bP,cL($CLJS.uL,$CLJS.H(["aggregation",JP,"options",new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.h(null,1,[$CLJS.us,":aggregation-options options"],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Br,!0],null),mR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),mR],null)],null)])));
$CLJS.Y(fQ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,2,[$CLJS.us,"aggregation clause or numeric expression",$CLJS.xi,function(a){return $CLJS.n(bL($CLJS.uL,a))?$CLJS.uL:YM}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uL,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,bP],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[YM,JP],null)],null));$CLJS.Y(DL,cL($CLJS.jF,$CLJS.H(["field",NR])));var IT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,DL],null);$CLJS.Y(cO,cL($CLJS.cF,$CLJS.H(["field",NR])));
JT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,cO],null);
$la=dL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof IT?new $CLJS.Cc(function(){return IT},$CLJS.kd(rla,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),
JN,"metabase/mbql/schema.cljc",15,$CLJS.jF,1,1049,1049,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jF,$CLJS.mF,$CLJS.V($CLJS.pj,yQ)],null),$CLJS.Gc,"Schema for a valid asc clause.",$CLJS.n(IT)?IT.H:null])):null));return $CLJS.n(a)?a:JN}(),IT],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof JT?new $CLJS.Cc(function(){return JT},$CLJS.kd(jka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,
$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),EP,"metabase/mbql/schema.cljc",16,$CLJS.cF,1,1050,1050,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cF,$CLJS.mF,$CLJS.V($CLJS.pj,yQ)],null),$CLJS.Gc,"Schema for a valid desc clause.",$CLJS.n(JT)?JT.H:null])):null));return $CLJS.n(a)?a:EP}(),JT],null)]));
KT=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.br,$CLJS.GL,$CLJS.vM,$CLJS.FE,$CLJS.Qs,$CLJS.Hj,$CLJS.BO],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,mR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mD,mR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hi,new $CLJS.h(null,1,[$CLJS.Br,!0],null),mR],null)],null);
ama=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,KT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.GL],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.sL,mR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TO,pR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),pR],null)],null)],null);
bma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,KT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.vM],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bQ,pR],null)],null)],null);
LT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,KT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null)],null)],null);cma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,pQ],null);
dma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,LT,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.FE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FE,KR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.QM,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,ZL],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ni,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jr,$CLJS.Fi,$CLJS.vr],
null)],null)],null)],null)],null);$CLJS.MT=new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.BO,null,$CLJS.Qs,null,$CLJS.ur,null,$CLJS.Hj,null],null),null);ema=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.br],null),$CLJS.MT);
$CLJS.Y(xP,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.xi,$CLJS.Ri],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FE,dma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.GL,ama],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.vM,bma],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wn,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,LT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,ema],null)],null)],null)],null)],null));
var NT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jr,mR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,xP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"keys in template tag map must match the :name of their values"],null),function(a){return $CLJS.Le(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return $CLJS.E.g(c,
$CLJS.U.h(b))},a)}],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,mR],null)],null)],null),fma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,NT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IE,$CLJS.vr],null)],null)],null),OT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fP],null),PT=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.kr,new $CLJS.h(null,1,[$CLJS.xi,function(a){return $CLJS.n($CLJS.dD($CLJS.sd,
$CLJS.iP)(a))?$CLJS.iP:$CLJS.lD}],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iP,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ts,NT,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.iP,$CLJS.vr],null)],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lD,OT],null)],null),QT=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.U,mR],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.cC,nR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Rka,mR],null),new $CLJS.P(null,3,5,$CLJS.Q,
[$CLJS.DN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,oR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.Aj],null)],null)],null),RT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,Sla,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.fr,new $CLJS.h(null,1,[$CLJS.us,"'card__\x3cid\x3e' string Table ID"],null),/^card__[1-9]\d*$/],null)],null),gma=$CLJS.Wf.g(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.br],null),
new $CLJS.Rg(null,new $CLJS.h(null,4,[$CLJS.jE,null,$CLJS.TD,null,$CLJS.cE,null,$CLJS.gE,null],null),null)),ST=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,nQ],null);
$CLJS.Y(hN,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),RT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),PT],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PQ,$CLJS.RS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ED,new $CLJS.h(null,1,[$CLJS.Br,!0],null),gma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,
4,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.us,"Valid join `:fields`: `:all`, `:none`, or a sequence of `:field` clauses that have `:join-alias`."],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.br,$CLJS.wx,$CLJS.cz],null),ST],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),mR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aK,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,rR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RN,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,QT],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"Joins must have either a `source-table` or `source-query`, but not both."],null),$CLJS.dD($CLJS.zz($CLJS.eO,$CLJS.HP),$CLJS.Oe($CLJS.dD($CLJS.eO,$CLJS.HP)))],null)],null));var hma=$CLJS.Q,TT;var UT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,hN],null)],null);
if($CLJS.rd(UT)&&$CLJS.E.g($CLJS.z(UT),$CLJS.aj)){var VT=$CLJS.y(UT);$CLJS.z(VT);var WT=$CLJS.B(VT),ima=$CLJS.sd($CLJS.z(WT))?WT:$CLJS.ae(null,WT),XT=$CLJS.y(ima),jma=$CLJS.z(XT),kma=$CLJS.B(XT);TT=$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,$CLJS.R.j(jma,$CLJS.si,1)],null),kma)}else TT=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,UT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"non-empty"],null),$CLJS.y],null)],null);
$CLJS.Y(jM,new $CLJS.P(null,3,5,hma,[$CLJS.lr,TT,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"All join aliases must be unique."],null),function(a){return $CLJS.OK($CLJS.ff($CLJS.Sa,$CLJS.cf.g($CLJS.YD,a)))}],null)],null));var lma=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,jM],null);
$CLJS.Y(nQ,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.h(null,1,[$CLJS.us,"Distinct, non-empty sequence of Field clauses"],null),eL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aj,new $CLJS.h(null,1,[$CLJS.si,1],null),$CLJS.LR],null))],null));
$CLJS.Y(fP,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,13,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.HP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),PT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.eO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),RT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bF,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aj,new $CLJS.h(null,1,[$CLJS.si,1],null),$CLJS.ZR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aF,new $CLJS.h(null,1,
[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aj,new $CLJS.h(null,1,[$CLJS.si,1],null),$CLJS.LR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ZO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jr,mR,$CLJS.rT],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.UD,new $CLJS.h(null,1,[$CLJS.Br,!0],null),ST],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.QE,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.RS],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jR,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),qR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.NQ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),eL(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.aj,new $CLJS.h(null,1,[$CLJS.si,1],null),$la],null))],null),new $CLJS.P(null,3,5,$CLJS.Q,[jP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[jP,pR],null),new $CLJS.P(null,2,5,$CLJS.Q,[Sja,pR],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),lma],
null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RN,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,QT],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"Query must specify either `:source-table` or `:source-query`, but not both."],null),function(a){return $CLJS.E.g(1,$CLJS.D($CLJS.Cl(a,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.HP,$CLJS.eO],null))))}],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,
new $CLJS.h(null,1,[$CLJS.us,"Fields specified in `:breakout` should not be specified in `:fields`; this is implied."],null),function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.aF);b=$CLJS.J.g(b,$CLJS.UD);return $CLJS.od($CLJS.ct.g($CLJS.Wg(a),$CLJS.Wg(b)))}],null)],null));
$CLJS.YT=$CLJS.zg([$CLJS.EF,$CLJS.BO,dN,PM,SN,aR,yN,$CLJS.$Q,$CLJS.Qs,xN,NL,UP,QQ,$CLJS.cM,MQ,aN,$CLJS.XN,vN,$CLJS.Hi,$CLJS.uN,vL,$CLJS.zM,$CLJS.tN,wO,$CLJS.ur,$CLJS.Hj,pN],[new $CLJS.h(null,1,[IN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.EF,null,$CLJS.BO,null,$CLJS.Qs,null,$CLJS.ur,null,$CLJS.Hj,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.BO,IN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.EF,null,$CLJS.BO,null,$CLJS.$Q,null,$CLJS.XN,null,$CLJS.Hi,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Ri,$CLJS.vj,$CLJS.uD,cQ,IN,new $CLJS.Rg(null,new $CLJS.h(null,1,[dN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,eR,$CLJS.uD,cQ,IN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.EF,null,PM,null,$CLJS.Qs,null,$CLJS.Hi,null,vL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,$CLJS.vj,$CLJS.uD,RL,IN,new $CLJS.Rg(null,new $CLJS.h(null,1,[SN,null],null),null)],null),new $CLJS.h(null,1,[IN,new $CLJS.Rg(null,new $CLJS.h(null,1,[aR,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,
eR,$CLJS.uD,hla,IN,new $CLJS.Rg(null,new $CLJS.h(null,1,[yN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.BO,IN,new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.$Q,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,eR,IN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.EF,null,PM,null,$CLJS.Qs,null,$CLJS.Hi,null,vL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,eR,$CLJS.uD,RL,IN,new $CLJS.Rg(null,new $CLJS.h(null,1,[xN,null],null),null)],null),new $CLJS.h(null,1,[IN,new $CLJS.Rg(null,
new $CLJS.h(null,1,[NL,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,$CLJS.vj,$CLJS.uD,cQ,IN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.EF,null,aR,null,NL,null,UP,null,vN,null,$CLJS.Hi,null,vL,null,$CLJS.Hj,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,$CLJS.vj,$CLJS.uD,RL,IN,new $CLJS.Rg(null,new $CLJS.h(null,1,[QQ,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.BO,IN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.$Q,null,$CLJS.cM,null],null),null)],null),new $CLJS.h(null,
3,[$CLJS.Ri,$CLJS.vj,$CLJS.uD,RL,IN,new $CLJS.Rg(null,new $CLJS.h(null,1,[MQ,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,$CLJS.vj,$CLJS.uD,RL,IN,new $CLJS.Rg(null,new $CLJS.h(null,1,[aN,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.BO,IN,new $CLJS.Rg(null,new $CLJS.h(null,5,[$CLJS.EF,null,$CLJS.BO,null,$CLJS.$Q,null,$CLJS.XN,null,$CLJS.Hi,null],null),null)],null),new $CLJS.h(null,1,[IN,new $CLJS.Rg(null,new $CLJS.h(null,1,[vN,null],null),null)],null),new $CLJS.h(null,1,[IN,
new $CLJS.Rg(null,new $CLJS.h(null,1,[$CLJS.Hi,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.BO,IN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.$Q,null,$CLJS.uN,null],null),null)],null),new $CLJS.h(null,1,[IN,new $CLJS.Rg(null,new $CLJS.h(null,1,[vL,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.BO,IN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.$Q,null,$CLJS.zM,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.BO,IN,new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.$Q,
null,$CLJS.tN,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,eR,$CLJS.uD,cQ,IN,new $CLJS.Rg(null,new $CLJS.h(null,1,[wO,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.ur,IN,new $CLJS.Rg(null,new $CLJS.h(null,3,[$CLJS.EF,null,$CLJS.Hi,null,$CLJS.ur,null],null),null)],null),new $CLJS.h(null,2,[$CLJS.Ri,$CLJS.vj,IN,new $CLJS.Rg(null,new $CLJS.h(null,8,[$CLJS.EF,null,aR,null,NL,null,UP,null,vN,null,$CLJS.Hi,null,vL,null,$CLJS.Hj,null],null),null)],null),new $CLJS.h(null,3,[$CLJS.Ri,
eR,$CLJS.uD,RL,IN,new $CLJS.Rg(null,new $CLJS.h(null,1,[pN,null],null),null)],null)]);$CLJS.Y(pQ,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.us,"valid parameter type"],null)],null),$CLJS.Ng($CLJS.YT)));$CLJS.Y(ZL,$CLJS.Wf.g(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.us,"valid template tag widget type"],null),$CLJS.cz],null),$CLJS.Ng($CLJS.YT)));
$CLJS.Y(CP,cL(Jja,$CLJS.H(["tag-name",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,mR,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hi,mR],null)],null)],null)])));var ZT=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,CP],null);$CLJS.Y(yL,cL($CLJS.FE,$CLJS.H(["target",new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.LR,ZT],null)])));var $T=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,yL],null);$CLJS.Y(tM,cL(sN,$CLJS.H(["target",ZT])));
var aU=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,tM],null),mma=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.LR,dL($CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof $T?new $CLJS.Cc(function(){return $T},$CLJS.kd(Hka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,
1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),nP,"metabase/mbql/schema.cljc",21,$CLJS.FE,1,1612,1612,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FE,$CLJS.qI,$CLJS.V($CLJS.pj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,FM,HN],null))],null),$CLJS.Gc,"Schema for a valid dimension clause.",$CLJS.n($T)?$T.H:null])):null));return $CLJS.n(a)?a:nP}(),$T],null),new $CLJS.P(null,2,5,$CLJS.Q,[function(){var a=iO.h($CLJS.ld("undefined"!==typeof $CLJS.fL&&
"undefined"!==typeof gL&&"undefined"!==typeof hL&&"undefined"!==typeof aU?new $CLJS.Cc(function(){return aU},$CLJS.kd(Wka,new $CLJS.h(null,1,[$CLJS.wj,!0],null)),$CLJS.zg([$CLJS.Ei,$CLJS.U,$CLJS.Gj,$CLJS.ei,iO,$CLJS.Bi,$CLJS.Si,$CLJS.Oj,eM,$CLJS.di,$CLJS.Cj,$CLJS.Fj],[$CLJS.kd(OP,new $CLJS.h(null,1,[$CLJS.Cj,"Schema for validating a *normalized* MBQL query. This is also the definitive grammar for MBQL, wow!"],null)),fR,"metabase/mbql/schema.cljc",20,sN,1,1615,1615,new $CLJS.P(null,3,5,$CLJS.Q,[sN,
$CLJS.qI,$CLJS.V($CLJS.pj,HN)],null),$CLJS.Gc,"Schema for a valid variable clause.",$CLJS.n(aU)?aU.H:null])):null));return $CLJS.n(a)?a:fR}(),aU],null)]))],null),ZK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,fM],null);
$CLJS.Y(fM,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.lr,a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"Query must specify either `:native` or `:query`, but not both."],null),$CLJS.dD($CLJS.zz($CLJS.iP,$CLJS.IE),$CLJS.Oe($CLJS.dD($CLJS.iP,$CLJS.IE)))],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"Native queries must specify `:native`; MBQL queries must specify `:query`."],null),function(b){var c=
$CLJS.O(b);b=$CLJS.J.g(c,$CLJS.iP);var d=$CLJS.J.g(c,$CLJS.IE);c=$CLJS.J.g(c,$CLJS.Ri);c=c instanceof $CLJS.M?c.S:null;switch(c){case "native":return b;case "query":return d;default:throw Error(["No matching clause: ",$CLJS.p.h(c)].join(""));}}],null)],null)}(new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.UO,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.er,new $CLJS.h(null,1,[$CLJS.us,"valid Database ID"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.eK],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.YJ],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.br,$CLJS.IE,$CLJS.iP],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iP,new $CLJS.h(null,1,[$CLJS.Br,!0],null),fma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.IE,new $CLJS.h(null,1,[$CLJS.Br,!0],null),OT],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,new $CLJS.P(null,
9,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Ri,cma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Hi,new $CLJS.h(null,1,[$CLJS.Br,!0],null),mR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qI,new $CLJS.h(null,1,[$CLJS.Br,!0],null),mma],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ej,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.U,new $CLJS.h(null,1,[$CLJS.Br,!0],null),mR],null),new $CLJS.P(null,3,5,$CLJS.Q,[Pja,new $CLJS.h(null,1,[$CLJS.Br,!0],null),
mR],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.XM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.vr],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.cK,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[Gla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),zR],null)],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[Gka,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[oO,new $CLJS.h(null,1,[$CLJS.Br,!0],null),qR],null),new $CLJS.P(null,3,5,$CLJS.Q,[YL,new $CLJS.h(null,1,[$CLJS.Br,!0],null),qR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"max-results-bare-rows must be less or equal to than max-results"],null),function(a){var b=
$CLJS.O(a);a=$CLJS.J.g(b,oO);b=$CLJS.J.g(b,YL);return $CLJS.Ra($CLJS.n(a)?b:a)?!0:a>=b}],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wka,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[qla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[Lja,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.ur],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[uja,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[Rja,new $CLJS.h(null,1,[$CLJS.Br,!0],null),$CLJS.ur],null),new $CLJS.P(null,3,5,$CLJS.Q,[ila,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wja,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.ur],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[wla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.ur],null)],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Gx,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,11,5,$CLJS.Q,[$CLJS.Aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.pM,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,18,5,$CLJS.Q,[$CLJS.br,$CLJS.gj,Tka,$CLJS.lN,Vja,Qla,Xka,mka,Ika,eka,Mja,ika,fla,uka,Gja,Wja,Pla,Mla],null)],null)],null),new $CLJS.P(null,
3,5,$CLJS.Q,[lla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,pR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[rka,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,pR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.bQ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,Rla],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Hla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,mR],null)],null),
new $CLJS.P(null,3,5,$CLJS.Q,[tka,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,pR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[Fla,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jr,$CLJS.vr,$CLJS.vr],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[nka,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,pR],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.oN,new $CLJS.h(null,
1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.aj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.jr,$CLJS.vr,$CLJS.vr],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.MJ,new $CLJS.h(null,1,[$CLJS.Br,!0],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.dr,$CLJS.vr],null)],null)],null)],null)],null)],null)),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.us,"`:source-metadata` should be added in the same level as `:source-query` (i.e., the 'inner' MBQL query.)"],
null),$CLJS.Oe($CLJS.RN)],null)],null));var bU=$CLJS.YK(ZK);(function(){var a=pja();return function(b){if($CLJS.n(bU.h?bU.h(b):bU.call(null,b)))return b;b=a.h?a.h(b):a.call(null,b);var c=$CLJS.VK(b);throw $CLJS.Uh($CLJS.fD("Invalid query: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([c]))])),new $CLJS.h(null,2,[$CLJS.Cx,c,Bka,b],null));}})();